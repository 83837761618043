import { Banner } from "@/ui/Banner/pc";
import Section1 from "@/ui/Main/section1/pc";
import Section2 from "@/ui/Main/section2/pc";
import Section3 from "@/ui/Main/section3/pc";
import Section4 from "@/ui/Main/section4/pc";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 90px;
`;

export default function PC() {
  return (
    <Container>
      <Section1 />
      <Section2 />
      {/* <Section3 /> */}
      <Section4 />
      <Banner />
    </Container>
  );
}
