import {
  small_service_item1,
  small_service_item10,
  small_service_item11,
  small_service_item12,
  small_service_item13,
  small_service_item14,
  small_service_item15,
  small_service_item16,
  small_service_item17,
  small_service_item18,
  small_service_item19,
  small_service_item2,
  small_service_item20,
  small_service_item21,
  small_service_item22,
  small_service_item23,
  small_service_item24,
  small_service_item25,
  small_service_item3,
  small_service_item4,
  small_service_item5,
  small_service_item6,
  small_service_item7,
  small_service_item8,
  small_service_item9,
} from "@/assets";
import { GridBox } from "@/components";
import { SimpleInput } from "@/components/SimpleInput";
import { getTheme } from "@/styles/theme";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  .innerSection {
    max-width: 1180px;
    width: 100%;
  }

  .bg-primary100 {
    background-color: ${getTheme("primary100")};
  }

  .bg-g3 {
    background-color: ${getTheme("g3")};
  }

  .bg-g1 {
    background-color: ${getTheme("g1")};
  }

  .w-6 {
    width: 24px;
  }

  .w-3px {
    width: 3px;
  }

  .h-6 {
    height: 24px;
  }

  .h-3px {
    height: 3px;
  }

  .rounded-48px {
    border-radius: 48px;
  }

  .rounded-16px {
    border-radius: 16px;
  }

  .let {
    letter-spacing: -2.8px;
  }

  .rounded {
    border-radius: 4px;
  }

  .button {
    width: 200px;
    height: 64px;
  }
`;

export default function PC() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const category = searchParams.get("category");
  const sub = searchParams.get("sub");

  const [selected, setSelected] = useState([]);

  const [content, setContent] = useState("");

  const [categoryList] = useState([
    { name: "도배" },
    { name: "바닥재" },
    { name: "주방/가구" },
    { name: "욕실 리모델링" },
    { name: "타일" },
    { name: "목공" },
    { name: "그 외" },
  ]);

  useEffect(() => {
    if (window !== undefined) {
      if (window.localStorage.getItem("service"))
        setSelected(JSON.parse(window.localStorage.getItem("service")));

      setContent(window.localStorage.getItem("content") ?? "");
    }
  }, []);

  return (
    <Container>
      <div className="w-full flex-center flex-col pb-160 px-20">
        <div className="innerSection flex-center pt-120 flex-col">
          <div className="flex-center mb-16">
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px c-white font-14 fontG">
              1
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px ml-6 c-white font-14 fontG">
              2
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              3
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              4
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              5
            </div>
          </div>

          <div className="font-18 mb-60">
            <span className="text-grey4">{category.replaceAll("^", "/")}</span>{" "}
            {">"}{" "}
            <span className="c-primary100 medium">
              {sub.replaceAll("^", "/")}
            </span>
          </div>

          <div className="c-black bold font-28 mb-40 let">
            필요하신 서비스를 선택해 주세요.
          </div>

          {categoryList.map((sub, key1) => (
            <div className="w-full" key={`sub-${key1}`}>
              <div className="flex align-center mb-32 mt-80 fontG">
                <div
                  className="bg-primary100 mr-12"
                  style={{
                    width: "4px",
                    height: "28px",
                    borderRadius: "6px",
                  }}
                />
                <div className="c-brown font-18">{sub.name}</div>
              </div>

              <GridBox col={window.innerWidth >= 1180 ? 4 : 3} gap={20}>
                {EstimateData.filter((item) => item.category === sub.name)?.map(
                  (post, key) => (
                    <div
                      key={`gallery-key-${key}`}
                      className="pointer"
                      onClick={() => {
                        if (!selected.includes(sub.name + " - " + post.name))
                          setSelected((list) => [
                            ...list,
                            sub.name + " - " + post.name,
                          ]);
                        else
                          setSelected((list) =>
                            list.filter(
                              (li) => li !== sub.name + " - " + post.name
                            )
                          );
                      }}
                    >
                      <img
                        className="w-full"
                        style={{
                          borderRadius: "10px",
                          border: selected.includes(
                            sub.name + " - " + post.name
                          )
                            ? "2px solid #FA7521"
                            : "",
                        }}
                        src={post.image}
                      />
                      <div className="mt-12 bold c-black">{post.name}</div>
                    </div>
                  )
                )}
              </GridBox>
            </div>
          ))}

          <div className="w-full">
            <div className="flex align-center mb-32 mt-80">
              <div
                className="bg-primary100 mr-12"
                style={{
                  width: "4px",
                  height: "28px",
                  borderRadius: "6px",
                }}
              />
              <div className="c-brown font-18">기타 요청사항</div>
            </div>

            <SimpleInput
              value={content}
              onChange={setContent}
              placeholder="기타 요청사항이 있으시면 작성해주세요."
            />
          </div>

          <div className="flex-center mt-80">
            <div
              className="pointer c-g3 bg-g1 font-20 medium button mr-20 rounded flex-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              이전
            </div>
            <div
              className="pointer c-white bg-primary100 font-20 medium button rounded flex-center"
              onClick={() => {
                if (selected.length === 0)
                  return alert("서비스를 하나 이상 선택해주세요!");
                window.localStorage.setItem(
                  "service",
                  JSON.stringify(selected)
                );
                window.localStorage.setItem("content", content);
                navigate(
                  `/estimate/small/time?category=${category}&sub=${sub}`
                );
              }}
            >
              다음
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

const EstimateData = [
  {
    name: "실크",
    category: "도배",
    image: small_service_item1,
  },
  {
    name: "합지",
    category: "도배",
    image: small_service_item2,
  },
  {
    name: "기타",
    category: "도배",
    image: small_service_item3,
  },

  {
    name: "강화마루",
    category: "바닥재",
    image: small_service_item4,
  },
  {
    name: "강마루",
    category: "바닥재",
    image: small_service_item5,
  },
  {
    name: "장판",
    category: "바닥재",
    image: small_service_item6,
  },
  {
    name: "대리석",
    category: "바닥재",
    image: small_service_item7,
  },
  {
    name: "타일",
    category: "바닥재",
    image: small_service_item8,
  },

  {
    name: "싱크대",
    category: "주방/가구",
    image: small_service_item9,
  },
  {
    name: "붙박이장",
    category: "주방/가구",
    image: small_service_item10,
  },
  {
    name: "신발장",
    category: "주방/가구",
    image: small_service_item11,
  },
  {
    name: "대리석",
    category: "주방/가구",
    image: small_service_item12,
  },

  {
    name: "전체 리모델링",
    category: "욕실 리모델링",
    image: small_service_item13,
  },
  {
    name: "양변기 및 수전",
    category: "욕실 리모델링",
    image: small_service_item14,
  },
  {
    name: "욕실타일",
    category: "욕실 리모델링",
    image: small_service_item15,
  },

  {
    name: "발코니",
    category: "타일",
    image: small_service_item16,
  },
  {
    name: "싱크대벽면",
    category: "타일",
    image: small_service_item17,
  },
  {
    name: "현관",
    category: "타일",
    image: small_service_item18,
  },

  {
    name: "문교체",
    category: "목공",
    image: small_service_item19,
  },
  {
    name: "몰딩교체",
    category: "목공",
    image: small_service_item20,
  },
  {
    name: "중문",
    category: "목공",
    image: small_service_item21,
  },

  {
    name: "탄성코드",
    category: "그 외",
    image: small_service_item22,
  },
  {
    name: "퍼티작업",
    category: "그 외",
    image: small_service_item23,
  },
  {
    name: "발코니확장",
    category: "그 외",
    image: small_service_item24,
  },
  {
    name: "창호",
    category: "그 외",
    image: small_service_item25,
  },
];
