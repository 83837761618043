import styled from "styled-components";

import { header_logo, ic_call } from "../../../assets";
import { Link, NavLink } from "react-router-dom";
import { getTheme } from "../../../styles/theme";

const Container = styled.div`
  width: 100%;
  height: 90px;
  background-color: #fff;
  position: fixed;
  top: 0;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
  z-index: 9;

  .innerHeader {
    width: 1180px;
    height: 100%;
  }

  .call_btn {
    width: 235px;
    height: 90px;
    background-color: ${getTheme("primary")};
  }
`;

const StyledLink = styled(NavLink)``;

export default function PC() {
  return (
    <Container className="flex-center">
      <div className="innerHeader flex-center">
        <a href="/">
          <img src={header_logo} />
        </a>

        <div className="flex-1"></div>

        <div className="flex medium font-18 c-black">
          <Link to="/repair" className="mr-48">
            수리/보수
          </Link>
          <Link to="/small" className="mr-48">
            스몰인테리어
          </Link>
          <Link to="/homecare" className="mr-48">
            홈케어
          </Link>
          <Link to="/portfolio" className="mr-48">
            포트폴리오
          </Link>
          <Link to="/story" className="mr-48">
            수리스토리
          </Link>
        </div>

        <a href="tel:1533-0474" className="flex-center call_btn">
          <img src={ic_call} />

          <div className="fontP medium ml-12 c-white font-22">1533-0474</div>
        </a>
      </div>
    </Container>
  );
}
