import { getImageUri } from "@/utils";
import { transform } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 99999;
  background-color: rgba(10, 10, 10, 0.7);

  & > div {
    max-width: ${({ isMobile }) => (isMobile ? "60vw" : "630px")};
    max-height: ${({ isMobile }) => (isMobile ? "60vh" : "720px")};
  }

  .innerPopup {
    /* max-width: ${({ isMobile }) => (isMobile ? "35vw" : "630px")};
    max-height: ${({ isMobile }) => (isMobile ? "32vh" : "720px")}; */
    .popupImage {
      width: 100%;
      height: 100%;
    }
  }
  .close-btn-wrapper {
    display: flex;
    /* width: ${({ isMobile }) => (isMobile ? "35vw" : "630px")};
    height: ${({ isMobile }) => (isMobile ? "40px" : "80px")}; */
    width: inherit;
    height: 40px;
  }

  .popupClose {
    width: ${({ isMobile }) => (isMobile ? "35vw" : "315px")};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #eee;
    font-size: ${({ isMobile }) => (isMobile ? "8px" : "16px")};
  }
`;

export default function Popup({
  _id,
  pcImage,
  mobileImage,
  popupUrl,
  isChecked,
  isMobile = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, [isOpen]);

  const HAS_VISITED_BEFORE = localStorage.getItem(`hasVisitedBefore_${_id}`);

  useEffect(() => {
    const handleShowModal = () => {
      if (HAS_VISITED_BEFORE && HAS_VISITED_BEFORE > new Date()) {
        return;
      }

      if (!HAS_VISITED_BEFORE) {
        setIsOpen(true);
      }
    };

    window.setTimeout(handleShowModal, 1000);
  }, [HAS_VISITED_BEFORE]);

  function onClosePopup24H() {
    let expires = new Date();
    expires = expires.setHours(expires.getHours() + 24);
    localStorage.setItem(`hasVisitedBefore_${_id}`, expires);
    setIsOpen(false);
  }
  function onClosePopup() {
    setIsOpen(false);
  }

  return (
    <>
      {isOpen && (
        <Container className="flex-col flex-center" onClick={onClosePopup} isMobile={isMobile}>
          <div>
            <a href={popupUrl} target={"_blank"}>
              <div className="innerPopup flex-col flex-center">
                {isMobile ? (
                  <img className="popupImage" src={getImageUri(mobileImage, "popup")} />
                ) : (
                  <img className="popupImage" src={getImageUri(pcImage, "popup")} />
                )}
              </div>
            </a>
            <div className="close-btn-wrapper">
              <div className="pointer popupClose text-center" onClick={onClosePopup24H}>
                오늘 하루 보지 않기
              </div>
              <div className="pointer popupClose  text-center" onClick={onClosePopup}>
                닫기
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
