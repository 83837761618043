import styled from "styled-components";
import useMobileCheck from "@/hooks/useMobileCheck";
import { default as Mobile } from "./Main/mobile";
import { default as PC } from "./Main/pc";
import Popup from "@/components/Popup";
import { useEffect, useState } from "react";
import axios from "@/lib/axios";

const Container = styled.div``;

export default function MainPage() {
  const isMobile = useMobileCheck();
  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [popupList, setPopupList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  // // Popup 데이터 가져오기
  async function getData() {
    try {
      const {
        data: { data, total, totalCount },
      } = await axios.get(`${backEndUrl}/popup/${page}`);

      if (data.length > 0) {
        const filtering = data.filter((popup) => popup.isChecked);
        setPopupList(filtering);
      } else setPopupList([]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container>
      {isMobile ? <Mobile /> : <PC />}

      {popupList?.map((post, key) => (
        <Popup {...post} key={`popup-key-${key}`} isMobile={isMobile} />
      ))}
    </Container>
  );
}
