import { createGlobalStyle } from "styled-components";
import theme, { getTheme } from "./theme";

const generateFontStyles = (start, end) => {
  let arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(`.font-${i}{font-size: ${i}px !important;}`);
  }
  return arr;
};

const generateLineHeightStyles = (start, end) => {
  let arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(`.line-${i}{line-height: ${i}px !important;}`);
  }
  return arr;
};

const generateColorStyles = () => {
  let arr = [];

  Object.keys(theme).forEach((item) => {
    arr.push(`.c-${item}{color: ${theme[item]} !important;}`);
  });

  return arr;
};

const generatePaddingStyles = (start, end) => {
  let arr = [];
  for (let i = start; i <= end; i += 2) {
    const css = `
    .p-${i}{padding: ${i}px;} 
    .pl-${i}{padding-left: ${i}px;}
    .pr-${i}{padding-right: ${i}px;}
    .pt-${i}{padding-top: ${i}px;}
    .pb-${i}{padding-bottom: ${i}px;} 
    .px-${i} {
      padding-left: ${i}px;
      padding-right: ${i}px;
    }
    .py-${i} {
      padding-top: ${i}px;
      padding-bottom: ${i}px;
    }
    `;
    arr.push(css);
  }
  return arr;
};

const generateMarginStyles = (start, end) => {
  let arr = [];
  for (let i = start; i <= end; i += 2) {
    const css = `
    .m-${i}{margin: ${i}px;}
    .ml-${i}{margin-left: ${i}px;}  
    .mr-${i}{margin-right: ${i}px;}
    .mb-${i}{margin-bottom: ${i}px;}
    .mt-${i}{margin-top: ${i}px;}
    .mx-${i} {
      margin-left: ${i}px;
      margin-right: ${i}px;
    }
    .my-${i} {
      margin-top: ${i}px;
      margin-bottom: ${i}px;
    }
    `;
    arr.push(css);
  }
  return arr;
};

const GlobalStyles = createGlobalStyle`

/* display */
.inline {
  display: inline;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.flex1 { 
  flex: 1;
}
.inline-flex {
  display: inline-flex;
}

/* flex */
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1 1 0%;
}

.flex-2 {
  flex: 2 1 0%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* justify-content */
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

/* align-items */
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-stretch {
  align-items: stretch;
}

/* user-select */
.select-none {
  user-select: none;
}
.select-auto {
  user-select: none;
}

/* text-align */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

/* text-overflow */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* font-weight */
.black {
  font-weight: 900;
}

.bold {
  font-weight: 700;
}
.regular {
  font-weight: 400;
}
.light {
  font-weight: 300;
}
.medium {
  font-weight: 500;
}

/* 
.light {
  font-family: "OTSBAggroL";
} */


.bold-300 {
  font-weight: 300;
}
.bold-400 {
  font-family: "SourceSansProRegular";
}
.bold-500 {
  font-weight: 500;
}
.bold-600 {
  font-family: "SourceSansProSemiBold";
}
.bold-700 {
  font-family: "SourceSansProBold";
}

.font-number {
  font-family: 'Roboto', 'Noto Sans KR';
}


/* font color */
${generateColorStyles()}

/* margin */
${generateMarginStyles(2, 200)}

.ml-auto {
  margin-left: auto;
}
/* padding */
${generatePaddingStyles(2, 240)}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.pointer {
  cursor: pointer;
}

.pre-line {
  white-space: pre-line;
}

.w-full, .width-100 {
  width: 100%;
}

.keep-all {
  word-break: keep-all;
}

.w-container {
  width: 1180px;
}

/* font-size */
${generateFontStyles(8, 60)}

/* line-height */
${generateLineHeightStyles(8, 60)}

/* scroll */
.scroll-visible {
  overflow-x: scroll;
  &:disabled {
    background: none;
  }
  &::-webkit-scrollbar {
    width: 6px;
    visibility: unset;
    display: unset;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #eee;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
}

.flip-h {
  transform: scaleX(-1) !important;
}

.flip-v {
  transform: scaleY(-1) !important;
}
@media screen and (max-width: 516px) {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    visibility: hidden;
    display: none;
  }
}

&::selection {
  /* background-color: ${getTheme("primary")};
  color: #fff; */
}
#ct-container {
  margin-top: 120px;
}

.card {
  width: 100%;
  height: 64px;
  padding: 14px;
  border-radius: 12px;  
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(34, 34, 34, 0.1);
}
      
.tag {
  width: fit-content;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: #ffc736;
}

.menuButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 8px;
  padding: 0 12px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(34, 34, 34, 0.2);
}

.underline {
  text-decoration: underline;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

`;

export default GlobalStyles;
