import {
  ic_next_black,
  ic_prev_grey,
  ic_prev_primary,
  main_section4_career_item1,
  main_section4_career_item2,
  main_section4_career_item3,
  main_section4_career_item4,
  main_section4_portfolio_item1,
  main_section4_portfolio_item2,
  main_section4_portfolio_item3,
  main_section4_portfolio_item4,
  portfolio_circle_hover,
} from "@/assets";
import { ImageModal } from "@/components";
import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
  padding: 80px 20px;
  overflow-x: hidden;

  .innerSection {
    width: 100%;
  }

  .careersImg {
    width: 100%;
  }

  .portfolioSection {
    img {
      width: 580px;
    }
  }

  .moreButton {
    width: fit-content;
    border: 1.5px solid #e0e0e0;
    transition: 0.3s;

    :hover {
      border: 1.5px solid #e86d1f;
    }
  }

  .slideCover {
    width: 100%;
  }
`;

const StyledSlider = styled(Slider)`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100vw - 20px);
  z-index: 1;
  cursor: pointer;

  .slide-item {
    .innerImgBefore,
    .innerImgAfter {
      width: 140px;
      height: 180px;
    }

    .hoverTextAfter,
    .hoverTextBefore {
      width: 100%;
      height: 100%;

      display: none;
      transition: 0.3s;

      background: rgba(253, 138, 18, 0.8);
    }
  }

  .innerImgBefore:hover {
    .hoverTextBefore {
      display: flex;
    }

    .itemMaskBefore {
      display: none;
    }
  }

  .innerImgAfter:hover {
    .hoverTextAfter {
      display: flex;
    }

    .itemMaskAfter {
      display: none;
    }
  }

  .itemMaskBefore,
  .itemMaskAfter {
    width: 100%;
    height: 100%;

    opacity: 1;

    background: rgba(0, 0, 0, 0.4);

    .circle {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 1.3px dashed #ffffff;
    }
  }
`;

export default function Section4() {
  const [careerCurrent, setCareerCurrent] = useState(0);
  const [prevHover, setPrevHover] = useState(false);
  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const awsUrl = process.env.REACT_APP_AWS_URL;

  const [exhibitionList, setExhibitionList] = useState([]);
  const [beforeImg, setBeforeImge] = useState([]);
  const [afterImg, setAfterImge] = useState([]);
  const [selectedImgs, setSelectedImgs] = useState([]);
  const [page, setPage] = useState(1);
  const [selectimg, setSelectimg] = useState(0);

  
  const getExhibitionPortfolio = async () => {
    try {
      const axiosResult = await axios({
        method: "GET",
        url: `${backEndUrl}/gallery/exhibition/${page}`,
      });
      const list = axiosResult.data;
      setExhibitionList(list);
      getImageUrl(list);
    } catch (err) {}
  };
  const getImageUrl = async (list) => {
    const newBeforeImages = [];
    const newAfterImages = [];
    list.map((item) => {
      const beforeImage = `${awsUrl}/gallery/${item.beforeImage}`;
      const afterImage = `${awsUrl}/gallery/${item.afterImage}`;
      newBeforeImages.push(beforeImage);
      newAfterImages.push(afterImage);
      setBeforeImge(newBeforeImages);
      setAfterImge(newAfterImages);
    });
  };
  useEffect(() => {
    getExhibitionPortfolio();
  }, []);
  const careerNext = () => {
    if (careerCurrent + 2 > CareersList.length) setCareerCurrent(0);
    else setCareerCurrent((cnt) => cnt + 1);
  };

  const careerPrev = () => {
    if (careerCurrent - 1 < 0) setCareerCurrent(CareersList.length - 1);
    else setCareerCurrent((cnt) => cnt - 1);
  };

  const options = {
    className: "slider variable-width",
    arrows: false,
    speed: 100,
    infinite: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    variableWidth: true,
    afterChange: (idx) => {
      setCurrent(idx);
    },
  };

  const slideRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [select, setSelect] = useState(0);

  return (
    <Container className="flex-col flex align-center relative">
      {/* <ImageModal
        isMobile={true}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        list={[ReviewList[select]?.before, ReviewList[select]?.after]}
      /> */}

      <div className="innerSection">
        <div className="c-primary font-14 meidum fontE">Service</div>

        <div className="relative flex align-center mt-14 font-20 medium fontE c-g900 line-28 mb-40">
          30년넘게 5600세대 이상의 고객을
          <br />
          유지할 수 있었던 이유.
        </div>

        <div className="w-full">
          <img className="careersImg" src={CareersList[careerCurrent].img} />

          <div
            className="px-20 py-30 flex-col"
            style={{
              marginTop: "-6px",
              height: "260px",
              border: "1px solid #e0e0e0",
            }}
          >
            <div className="fontE font-14 c-primary medium mb-16">0{careerCurrent + 1}</div>
            <div className="fontP bold font-18 c-black pre-line line-24 mb-10">
              {CareersList[careerCurrent].title}
            </div>
            <div className="c-g800 fontP line-24 keep-all">{CareersList[careerCurrent].des}</div>

            <div className="flex-1" />

            <div className="flex align-center">
              <img
                onClick={careerPrev}
                className="pointer"
                src={!prevHover ? ic_prev_grey : ic_prev_primary}
                onMouseOver={() => setPrevHover(true)}
                onMouseLeave={() => setPrevHover(false)}
              />
              <div className="mx-8 font-14 c-g300 mb-4">/</div>
              <img onClick={careerNext} className="pointer" src={ic_next_black} />
            </div>
          </div>
        </div>

        {/* <div className="c-primary mt-80 font-14 meidum fontE">Portfolio</div>

        <div className="relative mt-14 font-20 medium fontE c-g900 line-28 mb-40 flex align-center">
          미술관에 온듯한
          <br />
          아름다운 공간을 선물합니다.
          <div className="flex-1" />
        </div>

        <img className="w-full mb-10" src={main_section4_portfolio_item1} />
        <img className="w-full mb-10" src={main_section4_portfolio_item2} />
        <img className="w-full mb-10" src={main_section4_portfolio_item3} />
        <img className="w-full" src={main_section4_portfolio_item4} />

        <div className="flex-center mt-40">
          <Link to="/portfolio?index=1" className="moreButton pointer c-g800 font-18 px-60 py-16">
            더보기
          </Link>
        </div> */}

        <div className="c-primary mt-80 font-14 meidum fontE">Before / After</div>

        <div className="relative mt-14 flex align-center font-20 medium fontE c-g900 line-28 mb-40">
          긴말하지 않겠습니다.
          <br />
          후기로 증명하겠습니다.
        </div>
        <ImageModal isOpen={isOpen} onClose={() => setIsOpen(false)} list={selectedImgs}  selectImg={selectimg} />
        <div className="slideCover relative">
          <div className="relative" style={{ height: "180px" }}>
            <StyledSlider ref={slideRef} {...options}>
              {exhibitionList.map((post, key) => (
                <div
                  className="slide-item mr-10"
                  key={`slide3-post-key-${key}`}
                  onClick={() => {
                    setSelect(key);
                    setIsOpen(true);
                    setSelectedImgs([beforeImg[key], afterImg[key]]);
                  }}
                >
                  <div className=" flex align-center w-full">
                    <div
                      className="innerImgBefore"
                      style={{
                        backgroundImage: `url(${beforeImg[key]})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      onClick={()=>setSelectimg(0)}
                    >
                      <div className="itemMaskBefore flex-center">
                        <div className="circle flex-center fontE font-14 c-white">Before</div>
                      </div>

                      <div className="hoverTextBefore flex-center c-white font-14 bold pre-line text-center line-32">
                        <img
                          src={portfolio_circle_hover}
                          aria-label={portfolio_circle_hover}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>
                    </div>

                    <div
                      className="innerImgAfter"
                      style={{
                        backgroundImage: `url(${afterImg[key]})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}          
                      onClick={()=>setSelectimg(1)}
                    >
                      <div className="itemMaskAfter flex-center">
                        <div className="circle flex-center fontE font-14 c-white">After</div>
                      </div>

                      <div className="hoverTextAfter flex-center c-white font-14 bold pre-line text-center line-32">
                        <img
                          src={portfolio_circle_hover}
                          alt={portfolio_circle_hover}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex mt-14 align-end">
                    <div className="bold font-16 c-g900">{post.title}</div>
                    <div className="ml-4 c-g600 font-12 medium">{post.des}</div>
                  </div>
                </div>
              ))}
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  );
}

const CareersList = [
  {
    img: main_section4_career_item1,
    title: "인테리어에 대해 아무것도 몰라도\n홈매니저가 직접 알려드립니다.",
    des: "고객과 본사 홈매니저간 1:1 매칭을 토대로 고객의\n어려움을 직접 캐치하고 해결합니다.",
  },
  {
    img: main_section4_career_item2,
    title: "2007년 업계최초 가격표준화 정책시행",
    des: `인테리어 통합관리 솔루션 “보노야 홈플랜"을 통해\n투명한 견적을 수리홈즈를 찾는 모든 고객분께 제공드립니다.`,
  },
  {
    img: main_section4_career_item3,
    title: "작은 수리부터 큰공사까지\n완벽한 공사 마무리를 보장합니다.",
    des: "30년 넘은 인테리어 전문기업의 자회사로\n오랜기간 인정받은 기술력으로 정직원을 교육합니다.",
  },
  {
    img: main_section4_career_item4,
    title: "국내최초 온/오프라인 통합\n인테리어 플랫폼 운영",
    des: "고객이 만족하는 인테리어가 나올때까지\n1:1 밀착 풀케어 서비스를 제공합니다.",
  },
];
