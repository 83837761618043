import {
  ic_next_black,
  ic_prev_grey,
  ic_prev_primary,
  main_section4_career_item1,
  main_section4_career_item2,
  main_section4_career_item3,
  main_section4_career_item4,
  main_section4_portfolio_item1,
  main_section4_portfolio_item2,
  main_section4_portfolio_item3,
  main_section4_portfolio_item4,
  portfolio_circle_hover,
  portfolio_prev,
} from "@/assets";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImageModal } from "@/components";
import axios from "axios";

const Container = styled.div`
  overflow-x: hidden;
  padding-top: 120px;
  padding-bottom: 200px;

  .innerSection {
    max-width: 1180px;
    width: 100%;
  }

  .careersImg {
    width: 600px;
    height: 375px;
  }

  .portfolioSection {
    img {
      width: 100%;
    }
  }

  .slideCover {
    width: 100%;
  }

  .moreButton {
    width: fit-content;
    border: 1.5px solid #e0e0e0;
    transition: 0.3s;

    :hover {
      border: 1.5px solid #e86d1f;
    }
  }
`;

const StyledSlider = styled(Slider)`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(50vw + 590px);
  z-index: 1;
  cursor: pointer;

  .slide-item {
    .innerImgBefore,
    .innerImgAfter {
      width: 380px;
      height: 420px;
    }

    .hoverTextAfter,
    .hoverTextBefore {
      width: 100%;
      height: 100%;

      display: none;
      transition: 0.3s;

      background: rgba(253, 138, 18, 0.8);
    }
  }

  .innerImgBefore:hover {
    .hoverTextBefore {
      display: flex;
    }

    .itemMaskBefore {
      display: none;
    }
  }

  .innerImgAfter:hover {
    .hoverTextAfter {
      display: flex;
    }

    .itemMaskAfter {
      display: none;
    }
  }

  .itemMaskBefore,
  .itemMaskAfter {
    width: 100%;
    height: 100%;

    opacity: 1;

    background: rgba(0, 0, 0, 0.4);

    .circle {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      border: 1.3px dashed #ffffff;
    }
  }
`;

export default function Section4() {
  const [exhibitionList, setExhibitionList] = useState([]);
  const [beforeImg, setBeforeImge] = useState([]);
  const [afterImg, setAfterImge] = useState([]);
  const [selectedImgs, setSelectedImgs] = useState([]);
  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const awsUrl = process.env.REACT_APP_AWS_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [select, setSelect] = useState(0);
  const [page, setPage] = useState(1);  
  const [careerCurrent, setCareerCurrent] = useState(0);
  const [prevHover, setPrevHover] = useState(false);
  const [selectimg, setSelectimg] = useState(0);

  const [active, setActive] = useState(true);

  const getExhibiationPortfolio = async () => {
    try {
      const axiosResult = await axios({
        method: "GET",
        url: `${backEndUrl}/gallery/exhibition/${page}`,
      });
      const list = axiosResult.data;
      setExhibitionList(list);
    } catch (err) {}
  };
  const getImageUrl = async () => {
    const newBeforeImages = [];
    const newAfterImages = [];
    exhibitionList?.map((item) => {
      const beforeImage = `${awsUrl}/gallery/${item.beforeImage}`;
      const afterImage = `${awsUrl}/gallery/${item.afterImage}`;
      newBeforeImages.push(beforeImage);
      newAfterImages.push(afterImage);
      setBeforeImge(newBeforeImages);
      setAfterImge(newAfterImages);
    });
  };

  const careerNext = () => {
    if (careerCurrent + 2 > CareersList.length) setCareerCurrent(0);
    else setCareerCurrent((cnt) => cnt + 1);
  };

  const careerPrev = () => {
    if (careerCurrent - 1 < 0) setCareerCurrent(CareersList.length - 1);
    else setCareerCurrent((cnt) => cnt - 1);
  };

  const options = {
    className: "slider variable-width",
    arrows: false,
    speed: 100,
    infinite: true,

    cssEase: "linear",

    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    variableWidth: true,
    beforeChange: () => {
      setActive(false);
    },
    afterChange: (idx) => {
      setCurrent(idx);
      setActive(true);
    },
  };

  const slideRef = useRef();

  useEffect(() => {
    getExhibiationPortfolio();
  }, [page]);
  useEffect(() => {
    getImageUrl();
  }, [exhibitionList]);
  return (
    <Container className="flex-col px-20 flex align-center">
      <div className="innerSection">
        <div className="c-primary font-18 meidum fontE">Service</div>

        <div className="relative flex align-center mt-16 font-32 medium fontE c-g900 line-44 mb-60">
          30년넘게 5600세대 이상의 고객을
          <br />
          유지할 수 있었던 이유.
          <div className="flex-1" />
        </div>

        <div className="w-full flex align-center">
          <img className="careersImg" src={CareersList[careerCurrent].img} />

          <div className="px-60 py-58 flex-col" style={{ height: "375px" }}>
            <div className="fontE font-14 c-primary medium mb-32">0{careerCurrent + 1}</div>
            <div className="fontP bold font-24 c-black pre-line line-32 mb-16">
              {CareersList[careerCurrent].title}
            </div>
            <div className="c-g800 fontP line-24 pre-line">
              {CareersList[careerCurrent].des.replace(/\n/gi, " ")}
            </div>

            <div className="flex-1" />

            <div className="flex align-center">
              <img
                onClick={careerPrev}
                className="pointer"
                src={!prevHover ? ic_prev_grey : ic_prev_primary}
                onMouseOver={() => setPrevHover(true)}
                onMouseLeave={() => setPrevHover(false)}
              />
              <div className="mx-8 font-14 c-g300 mb-4">/</div>
              <img onClick={careerNext} className="pointer" src={ic_next_black} />
            </div>
          </div>
        </div>

        {/* <div className="c-primary mt-160 font-18 meidum fontE">Portfolio</div>

        <div className="relative mt-16 font-32 medium fontE c-g900 line-44 mb-80 flex align-center">
          미술관에 온듯한
          <br />
          아름다운 공간을 선물합니다.
          <div className="flex-1" />
        </div>

        <div className="flex-center portfolioSection mb-60">
          <div className="flex-col flex">
            <img className="mb-20" src={main_section4_portfolio_item1} />
            <img className="" src={main_section4_portfolio_item2} />
          </div>

          <div className="flex-col flex ml-20">
            <img className="mb-20" src={main_section4_portfolio_item3} />
            <img className="" src={main_section4_portfolio_item4} />
          </div>
        </div>

        <div className="flex-center">
          <Link to="/portfolio?index=1" className="moreButton pointer c-g800 font-18 px-60 py-16">
            더보기
          </Link>
        </div> */}

        <div className="c-primary mt-160 font-18 meidum fontE">Before / After</div>

        <div className="relative mt-16 flex align-center font-32 medium fontE c-g900 line-44 mb-80">
          긴말하지 않겠습니다.
          <br />
          후기로 증명하겠습니다.
          <div className="flex-1" />
        </div>

        <ImageModal isOpen={isOpen} onClose={() => setIsOpen(false)} list={selectedImgs}  selectImg={selectimg} />

        <div className="slideCover relative">
          <div
            className="absolute"
            style={{
              left: "-120px",
              height: "fit-content",
              top: 0,
              bottom: 0,
              margin: "auto 0",
            }}
          >
            <img
              src={portfolio_prev}
              alt={portfolio_prev}
              className="pointer"
              onClick={() => slideRef.current.slickNext()}
            />
          </div>
          <div className="relative mt-86" style={{ height: "480px" }}>
            <StyledSlider ref={slideRef} {...options}>
              {Array.isArray(exhibitionList) ? (
                exhibitionList?.map((post, key) => (
                  <div
                    className="slide-item mr-20"
                    key={`slide3-post-key-${key}`}
                    onClick={() => {
                      if (active) {
                        setSelect(key);
                        setIsOpen(true);
                        setSelectedImgs([beforeImg[key], afterImg[key]]);
                      }
                    }}
                  >
                    <div className=" flex align-center">
                      <div
                        className="innerImgBefore"
                        style={{
                          backgroundImage: `url(${beforeImg[key]})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        onClick={()=>setSelectimg(0)}
                      >
                        <div className="itemMaskBefore flex-center">
                          <div className="circle flex-center fontE font-26 c-white">Before</div>
                        </div>

                        <div className="hoverTextBefore flex-center c-white font-26 bold pre-line text-center line-32">
                          <img src={portfolio_circle_hover} />
                        </div>
                      </div>

                      <div
                        className="innerImgAfter"
                        style={{
                          backgroundImage: `url(${afterImg[key]})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        onClick={()=>setSelectimg(1)}
                      >
                        <div className="itemMaskAfter flex-center">
                          <div className="circle flex-center fontE font-26 c-white">After</div>
                        </div>

                        <div className="hoverTextAfter flex-center c-white font-26 bold pre-line text-center line-32">
                          <img src={portfolio_circle_hover} />
                        </div>
                      </div>
                    </div>

                    <div className="flex-column mt-32 align-end">
                      <div className="bold font-24 c-g900">{post.title}</div>
                      <div className="ml-8 c-g600 medium">{post.category}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div>Loading....</div>
              )}
            </StyledSlider>
          </div>
        </div>
      </div>
    </Container>
  );
}

const CareersList = [
  {
    img: main_section4_career_item1,
    title: "인테리어에 대해 아무것도 몰라도\n홈매니저가 직접 알려드립니다.",
    des: "고객과 본사 홈매니저간 1:1 매칭을 토대로 고객의\n어려움을 직접 캐치하고 해결합니다.",
  },
  {
    img: main_section4_career_item2,
    title: "2007년 업계최초 가격표준화 정책시행",
    des: `인테리어 통합관리 솔루션 “보노야 홈플랜"을 통해\n투명한 견적을 수리홈즈를 찾는 모든 고객분께 제공드립니다.`,
  },
  {
    img: main_section4_career_item3,
    title: "작은 수리부터 큰공사까지\n완벽한 공사 마무리를 보장합니다.",
    des: "30년 넘은 인테리어 전문기업의 자회사로\n오랜기간 인정받은 기술력으로 정직원을 교육합니다.",
  },
  {
    img: main_section4_career_item4,
    title: "국내최초 온/오프라인 통합\n인테리어 플랫폼 운영",
    des: "고객이 만족하는 인테리어가 나올때까지\n1:1 밀착 풀케어 서비스를 제공합니다.",
  },
];
