import styled from "styled-components";
import DatePicker from "react-datepicker";

import { ModalBackground } from "../Modal/ModalBackground";

import { ko } from "date-fns/esm/locale";

import "react-datepicker/dist/react-datepicker.css";

const Container = styled.div`
  position: relative;
  .react-datepicker {
    width: 844px !important;
    height: 622px;
    padding: 52px 60px;
    border-radius: 8px;
    user-select: none;
  }

  .react-datepicker__month-container {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__header {
    background-color: #fff !important;
    width: 100% !important;
    border: 0;
    padding: 0;
  }

  .react-datepicker__navigation--previous {
    top: 52px;
    left: 60px;
    background-image: url("/assets/icon/dateLeft.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 25px;
  }

  .react-datepicker__navigation--next {
    top: 52px;
    right: 60px;
    background-image: url("/assets/icon/dateRight.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 25px;
  }

  .react-datepicker__navigation-icon--next,
  .react-datepicker__navigation-icon--previous {
    height: 32px !important;
  }

  .react-datepicker__current-month {
    font-size: 20px;
    margin-bottom: 60px;
  }

  .react-datepicker__day {
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: auto;
    font-size: 24px;
  }

  .react-datepicker__day-name {
    width: 100%;
    margin: auto;
    font-weight: 500;
    font-size: 16px;
    font-family: "NotoSansKR";
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: grid;
    grid-template-columns: 7;
    grid-auto-flow: column;
  }

  .react-datepicker__day-names {
    margin-bottom: 32px;
  }
  .react-datepicker__day--today {
    font-weight: 400;
  }

  /* 
    .react-datepicker__day--today + .react-datepicker__day {
      background-color: #1426f2 !important;
      border-radius: 50%;
      color: #fff !important;
    } */

  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
    color: inherit;
  }
  /* .instruction {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #000;
      position: absolute;
      bottom: 50px;
      left: 11%;
    }
    .contents-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      max-height: 30px;
    }
    .today-color {
      width: 14px;
      height: 14px;
      background-color: #fa7521;
      border-radius: 50%;
    }
    .reservation-color {
      width: 14px;
      height: 14px;
      background-color: #1426f2;
      border-radius: 50%;
    }
    .reservation-info {
      margin-top: 8px;
      font-size: 12px;
    } */
`;

const PC = ({ isOpen, setIsOpen, startDate, setStartDate }) => {
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };

  const isDateSelectable = (date) => {
    return (
      date >= startDate || date.toDateString() === startDate.toDateString()
    );
  };
  return (
    <ModalBackground isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Container>
        <DatePicker
          startDate={startDate}
          onChange={handleChange}
          filterDate={isDateSelectable}
          inline
          locale={ko}
          formatWeekDay={(name) => {
            // return ["일", "월", "화", "수", "목", "금", "토"][name?.getDay()] + "요일"
            return name;
          }}
        />
      </Container>
    </ModalBackground>
  );
};

export default PC;
