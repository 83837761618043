'use client'

import styled from 'styled-components'
import {  mainSection3 } from '@/assets';
import { useScrollFadeIn } from '@/hooks';


const Container = styled.div`
  background-image: url(${mainSection3});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed; 

  .innerSection {
    max-width: 1180px;
    width: 100%;
    align-items: flex-end;
    
  }
`

export default function Section3 () {
  const animatedItem1 = useScrollFadeIn()


  return (
    <Container className='w-full flex flex-col px-20'>
      <div className="innerSection flex flex-col relative">
        <div className='c-primary fontE font-18 medium mb-60'>Overview</div>

        <div className='fontP font-40 c-g900 text-right line-56' {...animatedItem1}>
          <span className='bold'>수리홈즈는 “작은 변화”</span>로<br/>
          <span className='bold c-primary'>고객의 큰 행복</span>을 가질 수 있도록<br/>
          <span className='bold'>최선</span>을 다해 움직입니다.
        </div>

        <div style={{marginBottom: '226px'}}/>

        <div className='c-g400 font-20 fontP'>SURI-HOMES</div>
      </div>
    </Container>
  )
}