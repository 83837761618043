'use client'

import { GridBox } from "@/components";
import { SimpleInput } from "@/components/SimpleInput";
import { getTheme } from "@/styles/theme";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  .innerSection {
    width: 100%;
  }

  .bg-primary100 {
    background-color: ${getTheme('primary100')};
  }

  .bg-g3 {
    background-color: ${getTheme('g3')};
  }

  .bg-g1 {
    background-color: ${getTheme('g1')};
  }

  .w-6 {
    width: 20px;
  }

  .w-3px {
    width: 3px;
  }

  .h-6 {
    height: 20px;
  }

  .h-3px {
    height: 3px;
  }

  .rounded-48px {
    border-radius: 48px;
  }

  .rounded-16px {
    border-radius: 16px;
  }

  .let {
    letter-spacing: -2.8px;
  }

  .rounded {
    border-radius: 4px;
  }

  .button {
    flex: 1;
    height: 50px;
  }
  
  .h-60 {
    height: 46px;
  }

  .btn200  {
    flex: 1;
    height: 50px;
  }
`

const EstimateList = ['100만 원 이하', '500만 원 이하', '1,000만 원 이하', '3,000만 원 이하', '직접 입력', '상담 필요']


export default function Page() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const category = searchParams.get('category')
  const sub = searchParams.get('sub')
  
  const [content, setContent] = useState('')

  const [current, setCurrent] = useState(null)
  
  const [estiNum, setEstiNum] = useState()

  useEffect(() => {
    if (window !== undefined) {
      let wantPrice = window?.localStorage?.getItem('wantPrice')
      if(wantPrice) {
        let findList = EstimateList.find((item) => item === wantPrice)
        if(findList) {
          EstimateList.forEach((item, idx) => {
            if(item === wantPrice)
              setCurrent(idx)
          })
        }
        else {
          setCurrent(4)
          setEstiNum(Number(wantPrice.replaceAll('만 원 이하', '').replaceAll(',', '')))
        }
      }
    }
  }, [])

  return (
    <Container>
      <div className="w-full flex-center flex-col pb-60 px-16">
        <div className="innerSection flex-center pt-46 flex-col">
          <div className="flex-center mb-14">
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px c-white font-12 fontG">1</div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6"/>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6"/>
            
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px ml-6 c-white font-12 fontG">2</div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6"/>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6"/>
            
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px ml-6 c-white font-12 fontG">3</div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6"/>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6"/>
            
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px ml-6 c-white font-12 fontG">4</div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6"/>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6"/>
            
            <div className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-12 fontG" style={{border: '1px solid #a8adb4'}}>5</div>
          </div>

          <div className="c-black bold font-20 mb-24 mt-26 line-28 text-center">희망하는 예상 견적을<br/>선택해 주세요.</div>

          <GridBox col={2} gap={8} style={{width: '100%'}}>
            {
              EstimateList.map((post, key) => (
                <div className={`pointer w-full flex-center rounded medium font-13 ${key === current && 'c-primary100'}`} key={`time-select-key-${key}`} style={{
                  height: '60px',
                  border: key === current ?
                  '1px solid #FA7521' : '1px solid #D2D6DB'
                }} onClick={() => setCurrent(key)}>
                  {post}
                </div>
              ))
            }
          </GridBox>
          
          {current === 4 && 
            <div className="mt-22 flex-center" style={{width: '100%'}}>
              <div className="flex-1">
                <SimpleInput isMobile={true} validate={/^[0-9]+$/} placeholder="숫자를 입력해 주세요." value={estiNum} onChange={setEstiNum}/>
              </div>

              <div className="ml-12 c-black medium font-16">만 원 이하</div>
            </div>
          }


          <div className="flex-center w-full mt-40">
            <div className="pointer c-g3 bg-g1 font-16 medium btn200 mr-8 rounded flex-center" onClick={() => {
              navigate(-1)
            }}>이전</div>
            <div className="pointer c-white bg-primary100 font-16 medium btn200  rounded flex-center" onClick={() => {
              if(current === null) return alert('예상 견적을 선택해주세요!')
              if(current === 4 && estiNum === 0) return alert('예상 견적을 입력해주세요!')

              let wantPrice = current === 4 ? formatNumber(estiNum) + '만 원 이하' : EstimateList[current]
              
              window.localStorage.setItem('wantPrice', wantPrice)
              
              navigate(`/estimate/small/info?category=${category}&sub=${sub}`)
            }}>다음</div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const formatNumber = (value) => {
  if (isNaN(value)) {
    return 0
  }
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};