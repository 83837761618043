import { ImageUploadInput } from "@/components/ImageUploadInput";
import { getTheme } from "@/styles/theme";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import AWS from "@/lib/AWS";
import randomString from "randomstring";

const Container = styled.div`
  .innerSection {
    width: 100%;
  }

  .bg-primary100 {
    background-color: ${getTheme("primary100")};
  }

  .bg-g3 {
    background-color: ${getTheme("g3")};
  }

  .bg-g1 {
    background-color: ${getTheme("g1")};
  }

  .w-6 {
    width: 20px;
  }

  .w-3px {
    width: 3px;
  }

  .h-6 {
    height: 20px;
  }

  .h-3px {
    height: 3px;
  }

  .rounded-48px {
    border-radius: 48px;
  }

  .rounded-16px {
    border-radius: 16px;
  }

  .let {
    letter-spacing: -2.8px;
  }

  .rounded {
    border-radius: 4px;
  }

  .button {
    flex: 1;
    height: 50px;
  }
  .image-upload-section {
    width: 100vw;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .image-upload-list {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;

export default function Page() {
  const [searchParams, setSearchParams] = useSearchParams();
  const maxImageArray = [1, 2, 3, 4];

  const navigate = useNavigate();
  const category = searchParams.get("category");
  const sub = searchParams.get("sub");

  const [content, setContent] = useState("");
  const [targetPlaceImg, setTargetPlaceImg] = useState([]);
  const [targetPlaceImgName, setTargetPlaceImgName] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  async function submit() {
    if (!content) return alert("내용을 입력해주세요!");

    const randomStr = randomString.generate(20);
    const uploadedImageKeys = [];
    for (let i = 0; i < targetPlaceImg.length; i++) {
      const imageFile = targetPlaceImg[i];
      const imageName = targetPlaceImg[i]?.name;
      if (!imageFile) {
        continue;
      }
      if (!imageName) {
        continue;
      }
      const uploadKey = `estimate/${randomStr}/${imageName}`;

      const uploadThumb = new AWS.S3.ManagedUpload({
        params: {
          Bucket: "surihomes-file",
          Key: uploadKey,
          Body: imageFile,
          ContentType: "image/png",
          ContentDisposition: "inline",
        },
      });

      try {
        const result = await uploadThumb.promise();
        const imageUrl = uploadKey;
        uploadedImageKeys.push(imageUrl);
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    }
    window.localStorage.setItem("content", content);
    window.localStorage.setItem("uploadedImageKeys", JSON.stringify(uploadedImageKeys));
    navigate(`/estimate/homecare/time?category=${category}&sub=${sub}`);
  }
  return (
    <Container>
      <div className="w-full flex-center flex-col pb-60 px-16">
        <div className="innerSection flex-center pt-46 flex-col">
          <div className="flex-center mb-14">
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px c-white font-12 fontG">
              1
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-12 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              2
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-12 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              3
            </div>
          </div>

          {/* <div className="font-18 mb-60">
            홈케어 {'>'} <span>{category.replaceAll('^', '/')}</span> {'>'} <span className="c-primary100 medium">{sub.replaceAll('^', '/')}</span> 
          </div> */}

          <div className="c-black bold font-20 mb-24 ">고객님, 어떤 문제가 있으신가요?</div>

          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="rounded py-24 px-24"
            style={{
              width: "100%",
              height: "160px",
              border: "2px solid #fa7521",
              outlineColor: "#fa7521", // 포커스 되었을 시
              resize: "none",
            }}
          />
          <div className="border bg-grey2 mt-60 mb-60"> </div>
          <div className="image-upload-section">
            <div className="c-black bold font-28 mb-40 let">
              수리가 필요하신 부분의 사진을 올려주세요. (최대 4장)
            </div>
            <div className="image-upload-list">
              {maxImageArray.map((item, index) => (
                <ImageUploadInput
                  label="첨부파일"
                  placeholder="파일 선택"
                  efiles={targetPlaceImg}
                  setEFiles={setTargetPlaceImg}
                  setEfilesName={setTargetPlaceImgName}
                  maxSize={31457280}
                  accept="image/jpg,image/png,imaage/jpeg"
                  item={item}
                  index={index}
                  isMobile={true}
                />
              ))}
            </div>
          </div>
          <div className="flex-center mt-40 w-full">
            <div
              className="pointer c-g3 bg-g1 font-16 medium button mr-8 rounded flex-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              취소
            </div>
            <div
              className="pointer c-white bg-primary100 font-16 medium button rounded flex-center"
              onClick={() => submit()}
            >
              다음
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
