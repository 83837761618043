import { small_data_item1, small_data_item10, small_data_item11, small_data_item12, small_data_item2, small_data_item3, small_data_item4, small_data_item5, small_data_item6, small_data_item7, small_data_item8, small_data_item9 } from "@/assets";

export const SmallList = [
  {
    img: small_data_item1,
    title: '주방'
  },
  {
    img: small_data_item2,
    title: '욕실'
  },
  {
    img: small_data_item3,
    title: '타일'
  },
  
  {
    img: small_data_item6,
    title: '도배'
  },
  {
    img: small_data_item7,
    title: '마루'
  },
  {
    img: small_data_item8,
    title: '장판'
  },
  {
    img: small_data_item9,
    title: '필름'
  },

  {
    img: small_data_item11,
    title: '조명'
  },
  {
    img: small_data_item12,
    title: '중문'
  },
   
]
