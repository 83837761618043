import { banner_background } from "@/assets"
import { getTheme } from "@/styles/theme"
import { Link } from "react-router-dom"
import styled from "styled-components"


const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 60px;

  background-image: url(${banner_background});
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  
  .bg-black {
    background-color: ${getTheme('primary')};
  }

  .rounded {
    border-radius: 4px;
  }
`



export const Banner = ({ isMobile = false }) => {
  return (
    <Container className="w-full flex-col flex-center" isMobile={isMobile}>
      <div className="c-white medium fontE font-20 text-center line-28">
        당신의 작은 공간에<br/> 
        프리미엄 서비스를 더해드리겠습니다.
      </div>

      <Link to='/estimate' scroll={true}>
        <div className="bg-black fontP pointer px-30 py-12 mt-24 flex-center font-14 c-white medium">
        서비스 신청하기
        </div>
      </Link>
    </Container>
  )
}