import styled from "styled-components";
import { useState } from "react";
import { getTheme } from "@/styles/theme";

const Container = styled.div`
  height: 100px;
  input {
    border: 1px solid;
  }

  .rounded {
    border-radius: 4px;
  }
  .bg-text {
    background-color: ${getTheme("text")};
  }
  .pcInput {
    height: 60px;
  }

  .mobileInput {
    height: 46px;
  }

  .h-14 {
    height: 14px;
  }

  .h-20 {
    height: 20px;
  }
  .mobileBtn {
    width: 86px;
    min-width: 86px;
    height: 46px;
  }

  .pcBtn {
    width: 100px;
    height: 60px;
  }
`;

export const PhoneInput = ({
  label = "",
  value,
  onChange,
  wrong = false,
  wrongText = "",
  style = {},
  isMobile = false,
  validate = null,
  sendVerificationCode,
  checkIdentification,
  setCheckIdentification,
  setIsSendIdentification,
  remainingTime,
  ...rest
}) => {
  function handleChange(e) {
    if (!validate) onChange(e.target.value);
    else {
      if (validate.test(e.target.value) || e.target.value === "")
        onChange(e.target.value);
    }
  }
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  return (
    <Container style={style} className={`mb-${isMobile ? 16 : 20} w-full`}>
      {label && (
        <div className={`${isMobile ? "mb-6 font-12" : "mb-4"}`}>{label}</div>
      )}
      <div className="flex-center mb-12">
        <input
          className={`rounded ${
            isMobile ? "mobileInput pl-20 font-14" : "pcInput pl-24 font-20"
          }  w-full `}
          style={{
            borderColor: wrong ? "#F03D3E" : "#A8ADB4",
            outline: wrong ? "#F03D3E" : "#A8ADB4",
          }}
          value={value}
          onChange={(e) => {
            handleChange(e);
          }}
          {...rest}
        />
        <div
          className={`ml-10 pointer bg-text c-white  flex-column ${
            isMobile ? "mobileBtn font-14" : "pcBtn font-12"
          } rounded`}
          onClick={() => {
            if (wrong) return alert("휴대번호를 확인해주세요");

            sendVerificationCode();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>인증번호</p>
          <p style={{ margin: 0 }}>전송</p>
        </div>
      </div>

      <div
        className={`${
          isMobile ? "h-14 font-12 c-red mt-4" : "h-20 font-14 c-red mt-4"
        }`}
      >
        {wrong ? wrongText : ""}
        {remainingTime > 0 && (
          <div className={`mb-${isMobile ? 16 : 20} w-full`}>
            재발송 가능 시간: {formattedTime}
          </div>
        )}
      </div>
    </Container>
  );
};
