import { homecare_data_item1, homecare_data_item2, homecare_data_item3, homecare_data_item4 } from "@/assets";

export const HomecareList = [
  {
    img: homecare_data_item1,
    title: '청소'
  },
  {
    img: homecare_data_item2,
    title: '이사'
  },
  {
    img: homecare_data_item3,
    title: '줄눈/연마/코팅'
  },
  {
    img: homecare_data_item4,
    title: '살균/방역'
  },
   
]
