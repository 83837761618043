import { banner_background } from "@/assets"
import { getTheme } from "@/styles/theme"
import { Link } from "react-router-dom"
import styled from "styled-components"


const Container = styled.div`
  background-image: url(${banner_background});
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  
  height: 525px;
  width: 100%;

  .btn {
    background-color: #FFFFFF;
    :hover {
      color: #FFFFFF !important;
      background-color: ${getTheme('primary')};
    }
  }

  .rounded {
    border-radius: 4px;
  }
`



export const Banner = () => {
  return (
    <Container className="w-full flex-col flex-center">
      <div className="c-white medium fontE font-32 text-center line-44">
        당신의 작은 공간에<br/> 
        프리미엄 서비스를 더해드리겠습니다.
      </div>

      <Link to='/estimate' scroll={true}>
        <div className="btn fontP pointer px-48 py-20 mt-48 flex-center font-20 c-g900 medium rounded">
        서비스 신청하기
        </div>
      </Link>
    </Container>
  )
}