import styled from "styled-components";

const Container = styled.div`
  input {
    border: 1px solid;
  }

  .rounded {
    border-radius: 4px;
  }

  .pcInput {
    height: 60px;
  }

  .mobileInput {
    height: 46px;
  }

  .h-14 {
    height: 14px;
  }

  .h-20 {
    height: 20px;
  }
`;

export const Input = ({
  label = "",
  value,
  onChange,
  wrong = false,
  wrongText = "",
  style = {},
  isMobile = false,
  validate = null,
  ...rest
}) => {
  function handleChange(e) {
    if (!validate) onChange(e.target.value);
    else {
      if (validate.test(e.target.value) || e.target.value === "")
        onChange(e.target.value);
    }
  }

  return (
    <Container style={style} className={`mb-${isMobile ? 16 : 20} w-full`}>
      {label && (
        <div className={`${isMobile ? "mb-6 font-12" : "mb-4"}`}>{label}</div>
      )}

      <input
        className={`rounded ${
          isMobile ? "mobileInput pl-20 font-14" : "pcInput pl-24 font-20"
        }  w-full `}
        style={{
          borderColor: wrong ? "#F03D3E" : "#A8ADB4",
          outline: wrong ? "#F03D3E" : "#A8ADB4",
        }}
        value={value}
        onChange={(e) => {
          handleChange(e);
        }}
        {...rest}
      />
      <div
        className={`${
          isMobile ? "h-14 font-12 c-red mt-4" : "h-20 font-14 c-red mt-4"
        }`}
      >
        {wrong ? wrongText : ""}
      </div>
    </Container>
  );
};
