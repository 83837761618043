'use client'

import styled from 'styled-components'
import {  mainSection3 } from '@/assets';

const Container = styled.div`
  background-image: url(${mainSection3});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #dfff;
  background-position: center;
  align-items: center;
  background-attachment: fixed; 
  padding: 30px 20px;

  .innerSection {
    width: 100%;
    align-items: flex-end;
  }
`

export default function Section3 () {
  return (
    <Container className='w-full flex flex-col'>
      <div className="innerSection flex flex-col relative">
        <div className='c-primary fontE font-14 medium mb-24'>Overview</div>

        <div className='fontP font-20 c-g900 text-right line-28'>
          <span className='bold'>수리홈즈는 “작은 변화”</span>로<br/>
          <span className='bold c-primary'>고객의 큰 행복</span>을 가질 수 있도록<br/>
          <span className='bold'>최선</span>을 다해 움직입니다.
        </div>

        <div className='c-g400 mt-60 font-12 fontP'>SURI-HOMES</div>
      </div>
    </Container>
  )
}