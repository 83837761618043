import styled from "styled-components";
import { ic_banner_next_btn, ic_banner_prev_btn, ic_marker } from "@/assets";
import { Link } from "react-router-dom";
import { useScrollFadeIn } from "@/hooks";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { mainBanner } from "@/assets";

const StyledSlider = styled(Slider)`
  width: 100vw;
  height: 100%;
  max-height: 600px;
  .banner-wrapper {
    width: 100vw;
    display: flex;
    height: 600px !important;
    cursor: pointer; 
    position: relative;
  }
  .banner-content{
    width: 100%;
    display: flex;
  }
  .banner-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 600px !important;
  background-size: cover;
  background-position: center;
  z-index: -1;

}

  .banner-img{
    position: absolute;
    width: 100%;
    height: 100%;
  top: 0%;
  left:40%;
  

  }
  .banner-fixed-image {
    object-fit: cover;
    max-height: 100%;
  }

  .slick-dots li button:before {
    visibility: hidden;
  }
  .slick-dots {
    position: absolute;
    bottom: -180px;
    display: flex;
    gap: 20px;
  }
  .slick-dots li {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    content: "";
  }
  .slick-dots li.slick-active {
    background-color: #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    content: "";
  }

  .slick-next:before,
  .slick-prev:before {
    content: "";
  }
  .content-wrapper {
    position: absolute;
  left: 20%;
  top: 15%;
  z-index: 1; 
    

  }
  .servie-button {
    position: absolute;
    left: 20%;
    top: 50%;
    padding: 20px 30px;
    border: 1px solid #fff;
    width: fit-content;
    color: #fff;
    font-size: 16px;
    max-width: 350px;
  }
  .service-location {
    left: 20%;
    position: absolute;
    bottom: 100px;
  }
  .placeItem {
    padding: 4px 10px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  @media (max-width: 730px) {
    .banner-img{

    right: -40%;
  }
    .content-wrapper {
      left: 10%;
    }
    .servie-button {
      left: 10%;
    }
    .service-location {
      left: 10%;
    }
  }
  @media (max-width: 600px) {
    .banner-img{
      right: -30%;
}
    .content-wrapper {
      left: 5%;      z-index: 12;
    }
    .servie-button {
      left: 5%;
      z-index: 12;
    }
    .service-location {
      left: 5%;      z-index: 12;
    }
  }
  @media (max-width: 400px) {
    .banner-img{
      right: -20%;
      
}
    .content-wrapper {
      left: 5%;
    }
    .servie-button {
      left: 5%;
    }
    .service-location {
      left: 5%;
    }
  }
`;

export default function Section1() {
  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const awsUrl = process.env.REACT_APP_AWS_URL;

  const animatedItem1 = useScrollFadeIn();
  const animatedItem2 = useScrollFadeIn();
  const slideRef = useRef();
  const [bannerList, setBannerList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getBannerList();
  }, [page]);
  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.slickPlay();
    }
  }, [bannerList]);
  const getBannerList = async () => {
    try {
      const {
        data: { data, total, totalCount },
      } = await axios.get(`${backEndUrl}/banner/${page}`);

      if (data.length > 0) {
        const filtering = data.filter((banner) => banner.isChecked);
        setTotal(total);
        setBannerList(filtering);
        setTotalCount(totalCount);
      }
    } catch (err) {
      console.log(err);
    }
  };


const bannerListTest = [
  // {
  //   backgroundColor: "",
  //   bannerId: 3,
  //   bannerUrl: "https://surihomes.co.kr/small",
  //   createdAt: "2024. 05. 30. 09:41",
  //   isChecked: true,
  //   mobileButton: "서비스 신청 바로하기",
  //   mobileImage: "6NNpFoTl9rEG7_CTMhxQ/mobile.png",
  //   mobileSubTitle: "33년간 5700세대 이상 시공! ",
  //   mobileTitle: "작은 공간이 주는 큰 행복\n프리미엄 집수리\n& 스몰 인테리어",
  //   pcButton: "서비스 신청 바로하기",
  //   pcImage: "18opVo_2H_GEW8ggc-Us/pc.png",
  //   pcSubTitle: "33년간 5700세대 이상 시공!\n국내 최초 인테리어, 집수리/보수 토털 서비스",
  //   pcTitle: "작은 공간이 주는 큰 행복\n프리미엄 집수리 & 스몰 인테리어",
  //   _id: "654479540390e91f8f8cfb12",
  // },
  {
    bannerId: 1,
    bannerUrl: "https://www.surihomes.co.kr",
    createdAt: "2023. 11. 15. 12:50",
    isChecked: false,
    mobileButton: "수리홈즈가 제공하는 서비스 + ",
    mobileImage: null,
    mobileSubTitle: "33년간 5700세대 이상 시공! ",
    mobileTitle: "작은 공간이 주는 큰 행복\n프리미엄 집수리\n& 스몰 인테리어",
    pcButton: "수리홈즈가 제공하는 서비스 +",
    pcImage: null,
    pcSubTitle: "11111 ",
    pcTitle: " 111111",
    fixedImage: "6GgQLwSnV2UvGNCtxH-m/fixed.png",
    backgroundColor: "#bcb1b1",
    _id: "651d10a7b1a331eba313cde9",
  }
];

  const settings = {
    className: "slider variable-width",
    arrows: true,
    speed: 300,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dots: true,

    // afterChange: (currentIdx) => {
    //   const { document } = window;

    //   // 현재 슬라이드의 비디오를 재생하고 이전값들은 일시정지 및 초기화
    //   const currentVideoElement = document.querySelector(`[data-index="${currentIdx}"] video`);
    //   if (currentVideoElement != null) {
    //     currentVideoElement.currentTime = 0;
    //     var playPromise = currentVideoElement.play();
    //     if (playPromise !== undefined) {
    //       playPromise.then(() => {}).catch((error) => {});
    //     }
    //   }
    // },
  };

  return (
    <StyledSlider ref={slideRef} {...settings} className="mb-150">
        { bannerList?.map((banner, key) =>
          <a href={banner.bannerUrl} target="_blank">
               <div
            className="banner-wrapper"
            style={{ backgroundColor: banner.mobileImage ? 'transparent' : banner.backgroundColor }}
          >
            {banner.mobileImage && (
  <div
    className="banner-background"
    style={{ backgroundImage: `url(${awsUrl}/banner/${banner.mobileImage})` }}
  />
)}
                <div className="banner-contents">
          
                  <div className="content-wrapper">
                    <div
                      className="fontE medium mb-24 font-32 c-white line-40"
                      style={{ wordBreak: "keep-all", whiteSpace: "pre-line" }}
                    >
                      {banner.mobileTitle}
                    </div>
                    <div className="medium fontP mb-100 font-16 c-white line-28">
                      {banner.mobileSubTitle}
                    </div>
                  </div>
                  <div className="servie-button  font-16">{banner.mobileButton}</div>
                  <div className="service-location">
                    <div className="flex align-center mb-16">
                      <img src={ic_marker} alt="ic_marker" />
                      <div className="c-white ml-4 font-14 fontP bold">서비스지역</div>
                    </div>
                    <div className="flex align-center">
                      <div className="fontP medium placeItem mr-6">강남</div>
                      <div className="fontP medium placeItem mr-6">서초</div>
                      <div className="fontP medium placeItem mr-6">송파</div>
                      <div className="fontP medium placeItem mr-6">분당</div>
                    </div>
                  </div>
                </div>
                <div className="banner-img">
                  {banner.fixedImage &&
                <img
                  alt={banner.mobileTitle}
                  // src={`${mainBanner}`}
                  src={`${awsUrl}/banner/${banner.fixedImage}`}
                  className="banner-fixed-image"
                />
              }
                </div>
            </div>
            </a>
        )}
    </StyledSlider>
  );
}
