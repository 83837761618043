import { pick, isNil } from "lodash";

// IE 체크
export const detectIsIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
};

// Header 숨기기
export const hideHeader = (match) => {
  const el = document.getElementById("top-header");
  if (!el) return;
  match ? (el.style.visibility = "hidden") : (el.style.visibility = "visible");
};

// Footer 숨기기
export const hideFooter = (match) => {
  const el = document.getElementById("bottom-footer");
  if (!el) return;
  match ? (el.style.visibility = "hidden") : (el.style.visibility = "visible");
};

export const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

// 캡스락 켜져있는지 체크
export const checkOnCapsLock = (e) => {
  if (e.getModifierState("CapsLock")) return true;
  else return false;
};

// S3 이미지 경로 함수
export const getImageUri = (filename, directory) => {
  if (directory === "")
    return `https://surihomes-file.s3.ap-northeast-2.amazonaws.com/${filename}`;
  else
    return `https://surihomes-file.s3.ap-northeast-2.amazonaws.com/${directory}/${filename}`;
};

// 로컬스토리지 -> 유저 데이터 가져오기 *(보안 변경 필요)
export const getUserData = () => {
  const data = JSON.parse(localStorage.getItem("user_data"));
  return data;
};
