import { CategorySelector, GridBox, Pagination } from "@/components";

import { Banner } from "@/ui/Banner/pc";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import styled from "styled-components";

const Container = styled.div`
  padding-top: 110px;
  width: 100vw;
  padding-bottom: 160px;
  .innerContainer {
    width: 100%;
    max-width: 1180px;
    padding-bottom: 160px;
  }

  .story-list-wrapper {
    display: flex;
    cursor: pointer;
  }
  .story-item {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  .story-item img {
    width: 380px;
    object-fit: cover;
  }
  .story-content {
    display: flex;
    flex-direction: column;
    padding: 32px 40px 32px 56px;
    white-space: pre-wrap;
    word-break: keep-all;
  }

  .category {
    color: #fd8a12;
  }
  .summary {
    color: #9e9e9e;
  }
`;

export default function PC() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  let categoryFromQuery = location?.state?.category;
  if (location) {
    categoryFromQuery = location?.state?.category;
  }

  const index = searchParams.get("index");
  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const awsUrl = process.env.REACT_APP_AWS_URL;
  const navigate = useNavigate();
  const [current, setCurrent] = useState(index ? Number(index) : 0);
  const [category, setCategory] = useState(["뉴스&이벤트", "수리이야기"]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("뉴스&이벤트");
  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  async function getItemList() {
    let params = {
      page: page,
      limit: 12,
      skip: (page - 1) * 12,
      sort: 0,
      category: selectedCategory === "뉴스&이벤트" ? 1 : 2,
    };
    if (categoryFromQuery) {
      params.category = categoryFromQuery === "뉴스&이벤트" ? 0 : 1;
    }
    try {
      const {
        data: { data, total, totalCount },
      } = await axios.get(`${backEndUrl}/story`, { params });

      if (data != null) {
        const filtering = data.filter((story) => story.isChecked);
        console.log({ filtering });

        setTotal(total);
        setList(filtering);
        setTotalCount(totalCount);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getItemList();
  }, [selectedCategory]);
  useEffect(() => {
    getItemList();
  }, [page, selectedCategory]);
  useEffect(() => {
    if (list?.length !== 0) {
    }
  }, [list]);
  return (
    <Container className="w-full flex flex-col align-center">
      <div className="innerContainer">
        <div className="medium c-black font-32 mb-60 fontG">수리스토리</div>
        <CategorySelector
          options={category}
          current={categoryFromQuery ? (categoryFromQuery === "뉴스&이벤트" ? 0 : 1) : current}
          setSelectedCategory={setSelectedCategory}
          setCurrent={(idx) => {
            setCurrent(idx);
            setPage(1);
          }}
          categoryFromQuery={categoryFromQuery}
        />

        <div className="mt-100" />
        <GridBox gap={1} col={1}>
          {list?.map((post, key) => (
            <div
              onClick={() => navigate(`/story/detail/${post._id}`)}
              key={`gallery-key-${key}`}
              className="mb-20 story-list-wrapper"
            >
              <div className="story-item">
                <img src={`${awsUrl}/story/${post.storyThumbnail}`} alt="storyThumbnail" />
                <div className="story-content ">
                  <p className="category font-14 mb-30">{post.storyCategory}</p>
                  <h2 className=" font-20 bold mb-12">{post.storyTitle}</h2>
                  <p className="summary font-16">{post.storySummary}</p>
                </div>
              </div>
            </div>
          ))}
        </GridBox>

        <Pagination marginTop={24} currentPage={page} totalPage={total} onClickPage={setPage} />
      </div>

      <Banner />
    </Container>
  );
}
