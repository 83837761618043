import { GridBox } from "@/components";
import { ImageUploadInput } from "@/components/ImageUploadInput";
import { SimpleInput } from "@/components/SimpleInput";
import { getTheme } from "@/styles/theme";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import AWS from "@/lib/AWS";
import randomString from "randomstring";
const Container = styled.div`
  min-height: calc(100vh - 263px);
  .innerSection {
    max-width: 1180px;
    width: 100%;
  }
  .bg-primary100 {
    background-color: ${getTheme("primary100")};
  }

  .bg-g3 {
    background-color: ${getTheme("g3")};
  }

  .bg-g1 {
    background-color: ${getTheme("g1")};
  }

  .w-6 {
    width: 24px;
  }

  .w-3px {
    width: 3px;
  }

  .h-6 {
    height: 24px;
  }

  .h-3px {
    height: 3px;
  }

  .rounded-48px {
    border-radius: 48px;
  }

  .rounded-16px {
    border-radius: 16px;
  }

  .let {
    letter-spacing: -2.8px;
  }

  .rounded {
    border-radius: 4px;
  }
  .border {
    width: 980px;
    height: 1px;
  }
  .button {
    width: 200px;
    height: 64px;
  }
  .image-upload-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .image-upload-list {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
`;

export default function Page() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const maxImageArray = [1, 2, 3, 4];

  const category = searchParams.get("category");
  const sub = searchParams.get("sub");

  const [square, setSquare] = useState("");

  const [room, setRoom] = useState(null);
  const [targetPlaceImg, setTargetPlaceImg] = useState([]);
  const [targetPlaceImgName, setTargetPlaceImgName] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  useEffect(() => {
    if (window !== undefined) {
      setSquare(window.localStorage.getItem("supplyArea"));
      setRoom(Number(window.localStorage.getItem("roomCount")));
    }
  }, []);
  async function submit() {
    if (!square) return alert("공급면적기준을 입력해주세요!");
    window.localStorage.setItem("supplyArea", square);
    window.localStorage.setItem("roomCount", room);
    const randomStr = randomString.generate(20);
    const uploadedImageKeys = [];
    for (let i = 0; i < targetPlaceImg.length; i++) {
      const imageFile = targetPlaceImg[i];
      const imageName = targetPlaceImg[i]?.name;
      if (!imageFile) {
        continue;
      }
      if (!imageName) {
        continue;
      }
      const uploadKey = `estimate/${randomStr}/${imageName}`;

      const uploadThumb = new AWS.S3.ManagedUpload({
        params: {
          Bucket: "surihomes-file",
          Key: uploadKey,
          Body: imageFile,
          ContentType: "image/png",
          ContentDisposition: "inline",
        },
      });

      try {
        const result = await uploadThumb.promise();
        const imageUrl = uploadKey;
        uploadedImageKeys.push(imageUrl);
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    }
    window.localStorage.setItem("uploadedImageKeys", JSON.stringify(uploadedImageKeys));
    navigate(`/estimate/small/service?category=${category}&sub=${sub}`);
  }
  return (
    <Container>
      <div className="w-full flex-center flex-col pb-160">
        <div className="innerSection flex-center pt-120 flex-col">
          <div className="flex-center mb-16">
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px c-white font-14 fontG">
              1
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              2
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              3
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              4
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              5
            </div>
          </div>

          <div className="font-18 mb-60">
            <span className="text-grey4">{category.replaceAll("^", "/")}</span> {">"}{" "}
            <span className="c-primary100 medium">{sub.replaceAll("^", "/")}</span>
          </div>

          <div className="c-black bold font-28 mb-40 let">공급면적기준을 작성해 주세요.</div>

          <div className="flex align-center">
            <div style={{ width: "480px" }}>
              <SimpleInput
                validate={/^[0-9]+$/}
                value={square}
                onChange={setSquare}
                placeholder="숫자를 입력해 주세요."
              />
            </div>
            <div className="ml-16 font-20 medium">평</div>
          </div>

          <div
            className="my-60"
            style={{
              backgroundColor: "#dcdcdc",
              width: "100%",
              height: "1px",
            }}
          />

          <div className="c-black bold font-28 mb-40 let">
            시공이 필요한 방의 개수를 선택해 주세요.
          </div>

          <GridBox col={4} gap={20} style={{ width: "524px" }}>
            {["1개", "2개", "3개", "상담 필요"].map((post, key) => (
              <div
                className="font-medium rounded w-full pointer flex-center"
                key={`post-room-key-${key}`}
                style={{
                  border: key !== room ? "2px solid #D2D6DB" : "2px solid #FA7521",
                  height: "60px",
                  color: key === room && "#fa7521",
                }}
                onClick={() => setRoom(key)}
              >
                {post}
              </div>
            ))}
          </GridBox>
          <div
            className="my-60"
            style={{
              backgroundColor: "#dcdcdc",
              width: "100%",
              height: "1px",
            }}
          />
          <div className="image-upload-section">
            <div className="c-black bold font-28 mb-40 let">
              수리가 필요하신 부분의 사진을 올려주세요. (최대 4장)
            </div>

            <div className="image-upload-list">
              {maxImageArray.map((item, index) => (
                <ImageUploadInput
                  label="첨부파일"
                  placeholder="파일 선택"
                  efiles={targetPlaceImg}
                  setEFiles={setTargetPlaceImg}
                  setEfilesName={setTargetPlaceImgName}
                  maxSize={31457280}
                  accept="image/jpg,image/png,imaage/jpeg"
                  item={item}
                  index={index}
                />
              ))}
            </div>
          </div>
          <div className="flex-center mt-80">
            <div
              className="pointer c-g3 bg-g1 font-20 medium button mr-20 rounded flex-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              취소
            </div>
            <div
              className="pointer c-white bg-primary100 font-20 medium button rounded flex-center"
              onClick={() => {
                submit();
              }}
            >
              다음
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
