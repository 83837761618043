import { ic_check } from "@/assets";
import { getTheme } from "@/styles/theme";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  .h-450 {
    height: 450px;
  }

  .w-980 {
  }

  .w-132 {
    width: 132px;
  }

  .h-410 {
    height: 410px;
  }

  .bg-fafafa {
    background-color: #fafafa;
  }

  .rounded-16 {
    border-radius: 16px;
  }

  .h-44 {
    height: 44px;
  }

  .w-140 {
    width: 140px;
  }

  .h-47 {
    height: 47px;
  }

  .bg-black {
    background-color: ${getTheme('black')};
  }

  .rounded {
    border-radius: 4px;
  }
`

export default function Page() {
  const isMobile = true

  return (
    <Container className={`w-full flex-center flex-col ${isMobile ? 'pt-36 pb-36 px-16' : 'pt-120 pb-150'}`}>
      <div className={`${isMobile ? 'w-full h-450' : 'w-980 h-410'} flex-center flex-col rounded-16 bg-fafafa`}>
        <img
          src={ic_check}

          style={{
            width: isMobile ? '40px' : '48px',
            height: isMobile ? '40px' : '48px',
          }}
          className={`mb-${isMobile ? 24 : 32}`}
        />

        <div className={`bold font-${isMobile ? '20' : '24'} mb-16`}>수리 신청이 완료되었습니다!</div>

        <div className={`${isMobile ? 'mb-60 font-14' : 'mb-40'} text-center`}>
          수리홈즈 서비스를 신청해 주셔서 감사합니다.<br/>
          신청하신 시간에 맞춰 상담원이 연락 드리겠습니다.
        </div>

        <Link to='/' className={`${isMobile ? 'font-14 w-132 h-44 medium' : 'w-140 h-47 bold'} block flex-center bg-black rounded c-white `}>메인으로</Link>
        
      </div>
    </Container>
  )
}