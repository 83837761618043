import { useMobileCheck } from "@/hooks";
import styled from "styled-components";
import { default as PC } from "./pc";
import { default as Mobile } from "./mobile";

export const DateInput = ({
  isOpen,
  setIsOpen,
  startDate,
  setStartDate,
  isMobile = false,
}) => {
  return (
    <>
      {isMobile ? (
        <Mobile
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          startDate={startDate}
          setStartDate={setStartDate}
        />
      ) : (
        <PC
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          startDate={startDate}
          setStartDate={setStartDate}
        />
      )}
    </>
  );
};
