import { Routes, Route, BrowserRouter } from "react-router-dom";
import LayoutContainer from "../layouts/LayoutContainer";
import ErrorBoundary from "./ErrorBoundary";
import MainPage from "../pages/_app";
import NotFoundPage from "@/pages/404";
import FinishPage from "@/pages/estimate/finish";
import HomeCarePage from "@/pages/homecare/";
import HomeCareEstimatePage from "@/pages/estimate/homecare";
import HomeCareInfoPage from "@/pages/estimate/homecare/info";
import HomeCareTimePage from "@/pages/estimate/homecare/time";
import EstimatePage from "@/pages/estimate";
import SmallPage from "@/pages/small";
import SmallEstimatePage from "@/pages/estimate/small";
import SmallEstimateProductPage from "@/pages/estimate/small/estimate";
import SmallServicePage from "@/pages/estimate/small/service";
import SmallInfoPage from "@/pages/estimate/small/info";
import SmallTimePage from "@/pages/estimate/small/time";
import LoginPage from "@/pages/login";
import PolicyPrivacyPage from "@/pages/policy/privacy";
import PolicySerivicePage from "@/pages/policy/service";
import PortfolioPage from "@/pages/portfolio";
import RepairPage from "@/pages/repair";
import StoryPage from "@/pages/Story";
import StoryDetailPage from "@/pages/StoryDetail";
import React from "react";

export const AppRouter = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <LayoutContainer>
          <Routes>
            {/* 네비메뉴 라우트 */}
            <Route exact path="/" element={<MainPage />} />
            <Route path="/estimate" element={<EstimatePage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            {/* 홈케어 라우트 */}
            <Route path="/homecare" element={<HomeCarePage />} />
            <Route path="/repair" element={<RepairPage />} />
            <Route path="/estimate/homecare" element={<HomeCareEstimatePage />} />
            <Route path="/estimate/homecare/time" element={<HomeCareTimePage />} />
            <Route path="/estimate/homecare/info" element={<HomeCareInfoPage />} />
            {/* 스몰 라우트 */}
            <Route path="/small" element={<SmallPage />} />
            <Route path="/estimate/small" element={<SmallEstimatePage />} />>
            <Route path="/estimate/small/estimate" element={<SmallEstimateProductPage />} />
            <Route path="/estimate/small/service" element={<SmallServicePage />} />
            <Route path="/estimate/small/info" element={<SmallInfoPage />} />
            <Route path="/estimate/small/time" element={<SmallTimePage />} />
            {/* 수리스토리 라우트 */}
            <Route path="/story" element={<StoryPage />} />
            <Route path="/story/detail/:id" element={<StoryDetailPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/estimate/finish" element={<FinishPage />} />
            <Route path="/policy/privacy" element={<PolicyPrivacyPage />} />
            <Route path="/policy/service" element={<PolicySerivicePage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </LayoutContainer>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
