import styled from 'styled-components'


const Container = styled.div`
  .innerCircle { 
    width: 10px;
    height: 10px;
    background-color: #141414;
    border-radius: 50%;
  }

  .w-20 {
    width: 20px;
  }

  .h-20 {
    height: 20px;
  }

  .rounded40 {
    border-radius: 40px;
  }
`


export const RadioButton = ({
  label,
  selected=false,
  onClick,
  isMobile=false
}) => {

  return (
    <Container className='flex align-center pointer' onClick={onClick}>
      <div className='w-20 h-20 rounded40 flex-center' style={{
        border: '2px solid',
        borderColor: selected ? '#141414' : '#A8ADB4'
      }}>
        {selected && <div className='innerCircle'/>}

      </div>

      <div className='ml-10'>{label}</div>
    </Container>
  )
}