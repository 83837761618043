import styled from 'styled-components'


const Container = styled.div`
  .innerCircle { 
    width: 10px;
    height: 10px;
    background-color: #141414;
    border-radius: 50%;
  }

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #000;
  }
`

export const CheckBox = ({
  label,
  selected=false,
  onClick,
  isMobile=false
}) => {

  return (
    <Container className='flex align-center pointer' onClick={onClick}>
      <input type='checkbox' checked={selected}/>

      <div className='ml-10'>{label}</div>
    </Container>
  )
}