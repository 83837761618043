import { footer_logo, ic_instagram, ic_naver_blog } from "../../../assets";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 32px;
  padding-bottom: 20px;

  width: 100%;
  background: #141414;

  .innerContainer {
    width: 100%;
  }

  .footer_info {
  }
`;

export default function Mobile() {
  return (
    <Container className="flex-col align-center">
      <div className="innerContainer px-24">
        <div className="flex-center">
          <a href="/">
            <img src={footer_logo} />
          </a>

          <div className="flex-1" />

          <a href="https://blog.naver.com/surihomes" target={"_blank"}>
            <img className="pointer" src={ic_naver_blog}  style={{width:'30px'}}/>
          </a>
          <a
            href="https://www.instagram.com/surihomes_official/"
            target={"_blank"}
            style={{ marginLeft: 14 }}
          >
            <img className="pointer" src={ic_instagram}  style={{width:'30px'}} />
          </a>
        </div>

        <div className="flex align-center mt-6">
          <a href={"/policy/service"} className="pointer c-white font-14">
            이용약관
          </a>

          <div
            className="mx-8"
            style={{ width: "1px", height: "12px", backgroundColor: "#fff" }}
          ></div>

          <a href={"/policy/privacy"} className="pointer bold font-14 c-white">
            개인정보처리방침
          </a>
        </div>

        <div className="pt-24 footer_info">
          <div className="flex">
            <div className="c-g500 font-14 line-22">
              (주)수리홈즈
              <br />
              사업자등록번호: 884-81-01876
              <br />
              주소: 서울시 강남구 선릉로111길 38-10
              <br />
              서비스 신청: 1533-0474
            </div>
          </div>

          <div className="c-g500 font-10 mt-40">
            Copyright © 2010~2021 SURI-HOMES All Rights Reserved.
          </div>
        </div>
      </div>
    </Container>
  );
}
