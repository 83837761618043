import styled from 'styled-components'


const Container = styled.div`
  input {
    border: 1px solid;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  
  .pcInput {
    height: 60px;
  }

  .mobileInput {
    height: 46px;
  }
`


export const SimpleInput = ({
  value,
  onChange,
  style={},
  isMobile=false,
  validate=null,
  ...rest
}) => {
  function handleChange(e) {
    if(!validate) onChange(e.target.value)
    else {
      if(validate.test(e.target.value) || e.target.value === '') onChange(e.target.value)
    }
  }

  return (
    <Container style={style} className='w-full'>
      <input 
        className={`rounded ${isMobile ? 'mobileInput pl-20 font-14': 'pcInput pl-24 font-20'}  w-full `}
        style={{
          borderColor: '#A8ADB4',
          outline: '#A8ADB4'
        }}
        value={value}
        onChange={e => {
          handleChange(e)
        }} 
        {...rest}
      />
    </Container>
  )
}