import styled from "styled-components";
import DaumPostCode from "react-daum-postcode";
import { DaumPostModal } from "../Modal/DaumPostModal";
import { useState } from "react";
import { getTheme } from "@/styles/theme";

const Container = styled.div`
  input {
    border: 1px solid #a8adb4;
  }

  .rounded {
    border-radius: 4px;
  }

  .pcInput {
    height: 60px;
  }

  .mobileInput {
    height: 46px;
  }

  .bg-text {
    background-color: ${getTheme("text")};
  }

  .mobileBtn {
    width: 86px;
    min-width: 86px;
    height: 46px;
  }

  .pcBtn {
    width: 100px;
    height: 60px;
  }
`;

export const AddressInput = ({
  zonecode, // 우편번호
  setZonecode, // 우편번호 입력
  address, // 주소
  setAddress, // 주소 입력
  addressDetail, // 상세 주소
  setAddressDetail, // 상세 주소 입력
  style = {},
  isMobile = false,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleComplete = (data) => {
    setZonecode(data.zonecode);
    setAddress(data.address);
  };

  return (
    <Container style={style} className="mb-20 w-full">
      <div className={`mb-4 ${isMobile ? "font-12" : "font-16"}`}>주소</div>

      <div className="flex-center mb-12">
        <input
          className={`rounded flex-1 ${
            isMobile ? "mobileInput pl-20 font-14" : "pcInput pl-24 font-20"
          }`}
          style={{ borderColor: "#7B7E82" }}
          value={zonecode}
          readOnly={true}
          placeholder="우편번호"
        />
        <div
          className={`ml-10 pointer bg-text c-white flex-center ${
            isMobile ? "mobileBtn font-14" : "pcBtn font-20"
          } rounded`}
          onClick={() => setIsOpen(true)}
        >
          검색
        </div>
      </div>

      <input
        className={`rounded mb-12 w-full ${
          isMobile ? "mobileInput pl-20 font-14" : "pcInput pl-24 font-20"
        }`}
        value={address}
        readOnly={true}
        placeholder="주소"
      />

      <input
        className={`rounded mb-12 w-full ${
          isMobile ? "mobileInput pl-20 font-14" : "pcInput pl-24 font-20"
        }`}
        value={addressDetail}
        onChange={(e) => setAddressDetail(e.target.value)}
        placeholder="상세주소"
      />

      <DaumPostModal
        isOpen={isOpen}
        isMobile={isMobile}
        onClose={() => setIsOpen(false)}
        onSubmit={handleComplete}
      />
    </Container>
  );
};
