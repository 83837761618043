import { useEffect, useState } from "react";
import { ic_close_btn, ic_close } from "@/assets";
import styled from "styled-components";
import { ModalBackground } from "./ModalBackground";
import { useMobileCheck } from "@/hooks";

const Container = styled.div`
  .selectPhotoWrapper {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  .mobileImage {
    width: calc(100% - 20px);
    display: block;
    height: auto !important;
    inset: inherit !important;
  }

  .mobileImageBox {
    position: relative;
    width: calc(100vw - 100px);
  }
  .rounded-8 {
    border-radius: 8px;
  }
`;

export const ImageModal = ({
  isOpen,
  onClose,
  list = [],
  selectImg = 0

}) => {
  const [current, setCurrent] = useState(selectImg);
  const isMobile = useMobileCheck();
  useEffect(()=>{
    setCurrent(selectImg)

  },[selectImg])
  return (
    <ModalBackground
      isOpen={isOpen}
      onClose={() => {
    onClose();
      }}
    >
      <Container
        className="flex-center flex-col rounded-8"
        style={{ gap: "20px", backgroundColor: "white", padding: "20px" }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {!isMobile ? (
          <div className="relative">
            <img
              className="rounded-8"
              src={`${list[current]}`}
              style={{
                maxHeight: "600px",
                width: "800px",
                objectFit: "contain",
                border: "1px solid #cccccc",
              }}
              alt={`${list[current]}`}
            />
            <div
              className="font-16 absolute px-6 py-4 c-white"
              style={{
                background: "rgba(0, 0, 0, 0.6)",
                width: "100px",
                height: "30px",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "2%",
                left: "2%",
              }}
            >
              {current ? "After" : "Before"}
            </div>
          </div>
        ) : (
          <div className="relative">
            <img
              src={ic_close}
              alt={ic_close_btn}
              className="pointer absolute"
              style={{ right: "-6%", top: "-10%", zIndex: 19, color: "gray" }}
              onClick={() => {
                onClose();
                setCurrent(0);
              }}
            />
            <img
              className="mobileImage rounded-8"
              src={`${list[current]}`}
              style={{ width: "260px", height: "195px", maxHeight: "195px" }}
              alt={`${list[current]}`}
            />
            <div
              className="font-12 absolute px-6 py-4 c-white"
              style={{
                background: "rgba(0, 0, 0, 0.6)",
                width: "60px",
                height: "30px",
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "5%",
                left: "2%",
                boxSizing: "border-box",
              }}
            >
              {current ? "After" : "Before"}
            </div>
          </div>
        )}

        <div className="selectPhotoWrapper">
          {!isMobile ? (
            <img
              className="rounded-8 pointer"
              src={`${list[0]}`}
              style={{ height: "130px", width: "160px" }}
              onClick={() => setCurrent(0)}
              alt={`${list[0]}`}
            />
          ) : (
            <img
              className="rounded-8 pointer"
              src={`${list[0]}`}
              style={{ height: "50px", width: "60px" }}
              onClick={() => setCurrent(0)}
              alt={`${list[0]}`}
            />
          )}
          {!isMobile ? (
            <img
              className="rounded-8 pointer"
              src={`${list[1]}`}
              style={{ height: "130px", width: "160px" }}
              onClick={() => setCurrent(1)}
              alt={`${list[1]}`}
            />
          ) : (
            <img
              className="rounded-8 pointer"
              src={`${list[1]}`}
              style={{ height: "50px", width: "60px" }}
              onClick={() => setCurrent(1)}
              alt={`${list[1]}`}
            />
          )}
        </div>
      </Container>
    </ModalBackground>
  );
};
