import styled from "styled-components";
import { banner_background, ic_banner_next_btn, ic_banner_prev_btn, ic_marker } from "@/assets";
import { Link } from "react-router-dom";
import { useScrollFadeIn } from "@/hooks";
import { mainBanner } from "@/assets";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState, useEffect } from "react";
import axios from "axios";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        width: "48px",
        height: "48px",
        marginRight: "50px",
        marginTop: "100px",
        zIndex: "100",
      }}
      onClick={onClick}
    >
      <img src={ic_banner_next_btn} alt="next-btn" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        width: "48px",
        height: "48px",
        marginLeft: "50px",
        marginTop: "100px",
        zIndex: "100",
      }}
      onClick={onClick}
    >
      <img src={ic_banner_prev_btn} alt="prev-btn" />
    </div>
  );
}

const StyledSlider = styled(Slider)`
  width: 100%;
  height: 100%;
  max-height: 800px;
  .banner-wrapper {
    width: 100vw;
    display: flex;
    height: 800px;
    justify-content: flex-end;
    cursor: pointer;
    z-index: -3;
    position: relative;
  }
  .banner-content{
    width: 100%;
    display: flex;
  }
  .banner-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 800px !important;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .banner-img{
    width: 100%;
    display: flex;
  }
  .banner-fixed-image {
    object-fit: contain;
  }
  .slick-dots li button:before {
    visibility: hidden;  
  }
  .slick-dots {
    position: absolute;
    bottom: -180px;
    display: flex;
    gap: 20px;
  }
  .slick-dots li {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    content: "";
  }
  .slick-dots li.slick-active {
    background-color: #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    content: "";
  }

  .slick-next:before,
  .slick-prev:before {
    content: "";
  }
  .content-wrapper {
    
    position: absolute;
    left: 20%;
    bottom: 415px;
  }
  .service-button {
    position: absolute;
    left: 20%;
    bottom: 280px;
    padding: 18px 64px;
    border: 1px solid #fff;
    width: fit-content;
    color: #fff;
  }
  .service-location {
    left: 20%;
    position: absolute;
    bottom: 100px;
  }
  .placeItem {
    padding: 4px 10px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  @media (max-width: 1300px) {
    .content-wrapper {
      left: 10%;
    }
    .service-button {
      left: 10%;
    }
    .service-location {
      left: 10%;
    }
  }
  @media (max-width: 1100px) {
    .content-wrapper {
      left: 5%;
    }
    .service-button {
      left: 5%;
    }
    .service-location {
      left: 5%;
    }
  }
`;



export default function Section1() {
  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const awsUrl = process.env.REACT_APP_AWS_URL;
  const slideRef = useRef();
  const [bannerList, setBannerList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getBannerList();
  }, [page]);

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.slickPlay();
    }
  }, [bannerList]);

  const getBannerList = async () => {
    try {
      const {
        data: { data, total, totalCount },
      } = await axios.get(`${backEndUrl}/banner/${page}`);

      if (data.length > 0) {
        const filtering = data.filter((banner) => banner.isChecked);
        setTotal(total);
        setBannerList(filtering);
        setTotalCount(totalCount);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const settings = {
    className: "slider variable-width",
    arrows: true,
    speed: 300,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // afterChange: (currentIdx) => {
    //   const { document } = window;

    //   // 현재 슬라이드의 비디오를 재생하고 이전값들은 일시정지 및 초기화
    //   const currentVideoElement = document.querySelector(`[data-index="${currentIdx}"] video`);
    //   if (currentVideoElement != null) {
    //     currentVideoElement.currentTime = 0;
    //     var playPromise = currentVideoElement.play();
    //     if (playPromise !== undefined) {
    //       playPromise.then(() => {}).catch((error) => {});
    //     }
    //   }
    // },
  };

  return (
    <StyledSlider ref={slideRef} {...settings} className="mb-150">
      {bannerList?.map((banner, key) => (
        <a key={key} href={banner.bannerUrl} target="_blank" rel="noopener noreferrer">
          <div
            className="banner-wrapper"
            style={{ backgroundColor: banner.pcImage ? 'transparent' : banner.backgroundColor }}
          >
          {banner.pcImage && (
              <img
                alt={banner.pcTitle}
                src={`${awsUrl}/banner/${banner.pcImage}`}
                className="banner-background"
              />
            )}
            <div className="banner-content">
              <div className="content-wrapper">
                <div
                  className="fontE medium mb-24 font-48 c-white line-60"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {banner.pcTitle}
                </div>
                <div className="medium fontP mb-100 font-18 c-white line-28">
                  {banner.pcSubTitle}
                </div>
              </div>
              <div className="service-button">{banner.pcButton}</div>
              <div className="service-location">
                <div className="flex align-center mb-16">
                  <img src={ic_marker} alt="ic_marker" />
                  <div className="c-white ml-4 font-14 fontP bold">서비스지역</div>
                </div>
                <div className="flex align-center">
                  <div className="fontP medium placeItem mr-6">강남</div>
                  <div className="fontP medium placeItem mr-6">서초</div>
                  <div className="fontP medium placeItem mr-6">송파</div>
                  <div className="fontP medium placeItem mr-6">분당</div>
                </div>
              </div>
            </div>
            <div className="banner-img">
              {banner.fixedImage &&
                  <img
                  alt={banner.pcTitle}
                  src={`${awsUrl}/banner/${banner.fixedImage}`}
                  className="banner-fixed-image"
                />
              }
          
            </div>
          </div>
        </a>
      ))}
    </StyledSlider>
  );
}
