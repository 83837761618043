import { footer_logo, ic_instagram, ic_naver_blog } from "../../../assets";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 40px;
  padding-bottom: 60px;

  width: 100%;
  background: #141414;

  .innerContainer {
    max-width: 1180px;
    width: 100%;
  }

  .footer_info {
    border-top: 1px solid #616161;
  }
`;

export default function PC() {
  return (
    <Container className="flex-col px-20 align-center">
      <div className="innerContainer">
        <div className="flex-center">
          <Link to="/">
            <img src={footer_logo} />
          </Link>

          <div className="flex-1" />

          <div className="flex align-center">
            <a href={"/policy/service"} className="pointer c-white font-14">
              이용약관
            </a>

            <div
              className="mx-8"
              style={{ width: "1px", height: "12px", backgroundColor: "#fff" }}
            ></div>

            <a
              href={"/policy/privacy"}
              className="pointer bold font-14 c-white"
            >
              개인정보처리방침
            </a>
          </div>
        </div>

        <div className="pt-20 footer_info">
          <div className="flex">
            <div className="c-g500 font-14 line-26">
              (주)수리홈즈 ⏐ 사업자등록번호: 884-81-01876
              <br />
              주소: 서울시 강남구 선릉로111길 38-10 ⏐ 서비스 신청: 1533-0474
            </div>

            <div className="flex-1"/>

            <a href="https://blog.naver.com/surihomes" target={"_blank"}>
              <img className="pointer" src={ic_naver_blog} style={{width:'40px'}} />
            </a>
            <a
              href="https://www.instagram.com/surihomes_official/"
              target={"_blank"}
              style={{ marginLeft: 16, }}
            >
              <img className="pointer" src={ic_instagram} style={{width:'40px'}} />
            </a>
          </div>

          <div className="c-g500 font-12 mt-20">
            Copyright © 2010~2021 SURI-HOMES All Rights Reserved.
          </div>
        </div>
      </div>
    </Container>
  );
}
