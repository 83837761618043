import { default as PC } from "./pc";
import { default as Mobile } from "./mobile";

export const ImageUploadInput = ({
  idx,
  label,
  className = "",
  text,
  accept,
  guide,
  efiles,
  setEFiles,
  maxSize,
  maxSizeStr,
  setEfilesName,
  isMobile = false,
  item,
  index,
}) => {
  return (
    <>
      {isMobile ? (
        <Mobile
          label={label}
          efiles={efiles}
          setEFiles={setEFiles}
          setEfilesName={setEfilesName}
          maxSize={maxSize}
          accept={accept}
          item={item}
          index={index}
        />
      ) : (
        <PC
          label={label}
          efiles={efiles}
          setEFiles={setEFiles}
          setEfilesName={setEfilesName}
          maxSize={maxSize}
          accept={accept}
          item={item}
          index={index}
        />
      )}
    </>
  );
};
