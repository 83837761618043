const theme = {
  // [INIT] 컬러 셋 변경
  primary: "#FD8A12",
  primary100: "#FA7521",
  primary200: "#FF7F2E",
  primary300: "#FA8033",
  black: "#141414",
  white: "#ffffff",
  text: "#444444",
  g100: "#F5F5F5",
  g200: "#EEEEEE",
  g300: "#E0E0E0",
  g400: "#BDBDBD",
  g500: "#9E9E9E",
  g600: "#757575",
  g700: "#616161",
  g800: "#444444",
  g900: "#141414",
  g1: "#E6E9ED",
  g2: "#D2D6DB",
  g3: "#A8ADB4",
  g4: "#7B7E82",
  main: "#FFF9F5",
  grey: "#E1E1E1",
  grey2: "#DCDCDC",
  grey3: "#5B5B5B",
  grey4: "#a0a0a0",
  grey5: "#F3F3F4",
  grey6: "#757575",
  brown: "#803C11",
  red: "#F03D3E",
};

const fonts = {
  default: `'Noto Sans KR'`,
  serif: `'Noto Serif KR'; serif`,
  number: `Roboto`,
};

export const getTheme = (key) => {
  return key && theme[key] ? theme[key] : theme.gray900;
};

export const getFont = (key) => {
  return key && fonts[key] ? fonts[key] : fonts.default;
};

export default theme;
