import { useMobileCheck } from "@/hooks";
import styled from "styled-components";
import { default as PC } from "./pc";
import { default as Mobile } from "./mobile";

const Container = styled.div`
  padding-top: ${({ isMobile }) => (isMobile ? "56px" : "90px")};
`;

export default function Page() {
  const isMobile = useMobileCheck();

  return (
    <Container isMobile={isMobile}>{isMobile ? <Mobile /> : <PC />}</Container>
  );
}
