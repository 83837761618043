import { GridBox } from "@/components";
import { SimpleInput } from "@/components/SimpleInput";
import { getTheme } from "@/styles/theme";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import AWS from "@/lib/AWS";
import randomString from "randomstring";
const Container = styled.div`
  .innerSection {
    width: 100%;
  }

  .bg-primary100 {
    background-color: ${getTheme("primary100")};
  }

  .bg-g3 {
    background-color: ${getTheme("g3")};
  }

  .bg-g1 {
    background-color: ${getTheme("g1")};
  }

  .w-6 {
    width: 20px;
  }

  .w-3px {
    width: 3px;
  }

  .h-6 {
    height: 20px;
  }

  .h-3px {
    height: 3px;
  }

  .rounded-48px {
    border-radius: 48px;
  }

  .rounded-16px {
    border-radius: 16px;
  }

  .let {
    letter-spacing: -2.8px;
  }

  .rounded {
    border-radius: 4px;
  }

  .button {
    flex: 1;
    height: 50px;
  }
  .image-upload-section {
    width: 100vw;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .image-upload-list {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;

export default function Page() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const category = searchParams.get("category");
  const sub = searchParams.get("sub");

  const maxImageArray = [1, 2, 3, 4];

  const [square, setSquare] = useState("");

  const [room, setRoom] = useState(null);
  const [targetPlaceImg, setTargetPlaceImg] = useState([]);

  async function submit() {
    if (!square) return alert("공급면적기준을 입력해주세요!");

    const randomStr = randomString.generate(20);
    const uploadedImageKeys = [];
    for (let i = 0; i < targetPlaceImg.length; i++) {
      const imageFile = targetPlaceImg[i];
      const imageName = targetPlaceImg[i]?.name;
      if (!imageFile) {
        continue;
      }
      if (!imageName) {
        continue;
      }
      const uploadKey = `estimate/${randomStr}/${imageName}`;

      const uploadThumb = new AWS.S3.ManagedUpload({
        params: {
          Bucket: "surihomes-file",
          Key: uploadKey,
          Body: imageFile,
          ContentType: "image/png",
          ContentDisposition: "inline",
        },
      });

      try {
        const result = await uploadThumb.promise();
        const imageUrl = uploadKey;
        uploadedImageKeys.push(imageUrl);
      } catch (error) {
        console.error("Image upload failed:", error);
      }
    }
    window.localStorage.setItem("supplyArea", square);
    window.localStorage.setItem("roomCount", room);
    window.localStorage.setItem("uploadedImageKeys", JSON.stringify(uploadedImageKeys));
    navigate(`/estimate/small/service?category=${category}&sub=${sub}`);
  }
  useEffect(() => {
    if (window !== undefined) {
      setSquare(window.localStorage.getItem("supplyArea"));
      setRoom(Number(window.localStorage.getItem("roomCount")));
    }
  }, []);

  return (
    <Container>
      <div className="w-full flex-center flex-col pb-60 px-16">
        <div className="innerSection flex-center pt-46 flex-col">
          <div className="flex-center mb-40">
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px c-white font-12 fontG">
              1
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-12 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              2
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-12 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              3
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-12 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              4
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-12 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              5
            </div>
          </div>

          <div className="c-black bold font-20 mb-24">공급면적기준을 작성해 주세요.</div>
          <div className="flex align-center w-full">
            <div className="w-full">
              <SimpleInput
                validate={/^[0-9]+$/}
                value={square}
                onChange={setSquare}
                placeholder="숫자를 입력해 주세요."
                isMobile={true}
              />
            </div>
            <div className="ml-12 medium" style={{ width: "80px" }}>
              평(m2)
            </div>
          </div>

          <div
            className="my-40 w-full"
            style={{
              backgroundColor: "#dcdcdc",
              height: "1px",
            }}
          />

          <div className="c-black bold font-20 mb-24 text-center">
            시공이 필요한 방의 개수를
            <br />
            선택해 주세요.
          </div>

          <GridBox col={4} gap={8} className="w-full">
            {["1개", "2개", "3개", "상담 필요"].map((post, key) => (
              <div
                className="medium font-14 rounded w-full pointer flex-center"
                key={`post-room-key-${key}`}
                style={{
                  border: key !== room ? "2px solid #D2D6DB" : "2px solid #FA7521",
                  color: key === room && "#fa7521",
                  height: "46px",
                }}
                onClick={() => setRoom(key)}
              >
                {post}
              </div>
            ))}
          </GridBox>

          <div className="flex-center mt-40 w-full">
            <div
              className="pointer c-g3 bg-g1 font-16 medium button mr-8 rounded flex-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              취소
            </div>
            <div
              className="pointer c-white bg-primary100 font-16 medium button rounded flex-center"
              onClick={() => {
                if (!square) return alert("공급면적기준을 입력해주세요!");
                window.localStorage.setItem("supplyArea", square);
                window.localStorage.setItem("roomCount", room);
                navigate(`/estimate/small/service?category=${category}&sub=${sub}`);
              }}
            >
              다음
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
