import styled from "styled-components";
import Menu from "../../../ui/Header/Menu";
import { useState } from "react";
import { header_logo, ic_call, ic_menu_open } from "../../../assets";
import { Link } from "react-router-dom";
import { getTheme } from "../../../styles/theme";

const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 999999;

  width: 100vw;
  height: 56px;
  background-color: #fff;

  border-bottom: 2px solid #bdbdbd;
  padding: 0 16px;

  .call_btn {
    width: 122px;
    height: 56px;
    background-color: ${getTheme("primary")};
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export default function Mobile() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container className="flex-center bg-white">
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <InnerContainer className="flex-center">
        <a href="/" style={{ height: "36px" }}>
          <img style={{ width: "90px" }} src={header_logo} />
        </a>

        <div className="flex-1" />

        <a href="tel:1533-0474" className="flex-center call_btn">
          <img src={ic_call} style={{ height: "16px", width: "16px" }} />

          <div className="fontP medium ml-4 c-white font-12">1533-0474</div>
        </a>

        <img className="menuBtn ml-16 py-4" src={ic_menu_open} onClick={() => setIsOpen(true)} />
      </InnerContainer>
    </Container>
  );
}
