import { DateInput, GridBox } from "@/components";
import { getTheme } from "@/styles/theme";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

const Container = styled.div`
  .innerSection {
    max-width: 980px;
    width: 100%;
  }

  .bg-primary100 {
    background-color: ${getTheme("primary100")};
  }

  .bg-g3 {
    background-color: ${getTheme("g3")};
  }

  .bg-g1 {
    background-color: ${getTheme("g1")};
  }

  .w-6 {
    width: 24px;
  }

  .w-3px {
    width: 3px;
  }

  .h-6 {
    height: 24px;
  }

  .h-3px {
    height: 3px;
  }

  .rounded-48px {
    border-radius: 48px;
  }

  .rounded-16px {
    border-radius: 16px;
  }

  .let {
    letter-spacing: -2.8px;
  }

  .rounded {
    border-radius: 4px;
  }

  .button {
    width: 200px;
    height: 64px;
  }

  .h-60 {
    height: 60px;
  }

  .btn200 {
    width: 200px;
    height: 64px;
  }
  .c-g {
    color: #757575;
  }
`;

const DateTypeList = ["날짜 직접 선택", "상관없음"];
const TimeList = ["10시", "11시", "12시", "13시", "14시", "15시", "16시", "17시", "18시", "19시"];

const VisitDateList = ["날짜 직접 선택", "상관없음 (3개월 내 진행)"];

export default function Page() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const category = searchParams.get("category");
  const sub = searchParams.get("sub");

  const [dateType, setDateType] = useState(1);
  const [visitType, setVisitType] = useState(1);
  const [currentTime, setCurrentTime] = useState(null);

  const [startDate] = useState(new Date());

  const [inputDate, setInputDate] = useState(null);

  const [visitDate, setVisitDate] = useState(null);

  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isVisitOpen, setIsVisitOpen] = useState(false);

  useEffect(() => {
    if (window !== undefined) {
      let callDate = window?.localStorage?.getItem("callDate");
      let wantDate = window?.localStorage?.getItem("wantDate");

      if (window?.localStorage?.getItem("callTime"))
        TimeList.forEach((item, idx) => {
          if (item === window?.localStorage?.getItem("callTime")) setCurrentTime(idx);
        });

      if (callDate && callDate !== "상관 없음") {
        setInputDate(`${callDate.slice(0, 4)}. ${callDate.slice(4, 6)}. ${callDate.slice(6, 8)}`);
        setDateType(0);
      }
      if (wantDate && wantDate !== "상관 없음") {
        setVisitDate(`${wantDate.slice(0, 4)}. ${wantDate.slice(4, 6)}. ${wantDate.slice(6, 8)}`);
        setVisitType(0);
      }
    }
  }, []);

  return (
    <Container>
      <div className="w-full flex-center flex-col pb-160">
        <div className="innerSection flex-center pt-120 flex-col">
          <div className="flex-center mb-16">
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px c-white font-14 fontG">
              1
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px ml-6 c-white font-14 fontG">
              2
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px ml-6 c-white font-14 fontG">
              3
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              4
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div
              className="bg-white w-6 h-6 flex-center rounded-48px ml-6 c-g3 font-14 fontG"
              style={{ border: "1px solid #a8adb4" }}
            >
              5
            </div>
          </div>

          <div className="font-18 mb-60">
            <span>{category.replaceAll("^", "/")}</span> {">"}{" "}
            <span className="c-primary100 medium">{sub.replaceAll("^", "/")}</span>
          </div>

          <DateInput
            startDate={startDate}
            setStartDate={(e) => {
              let inputed = moment(e).format("YYYY. MM. DD");
              setInputDate(inputed);
              setDateType(0);
            }}
            isOpen={isDateOpen}
            setIsOpen={(value) => {
              setIsDateOpen(value);
              setDateType(1);
            }}
          />

          <DateInput
            startDate={startDate}
            setStartDate={(e) => {
              console.log(e);
              let inputed = moment(e).format("YYYY. MM. DD");
              setVisitDate(inputed);
              setVisitType(0);
            }}
            isOpen={isVisitOpen}
            setIsOpen={(value) => {
              setIsVisitOpen(value);
              setVisitType(1);
            }}
          />

          <div className="c-black bold font-28 mb-40 let">
            상담을 위한 통화 가능 시간대를 선택해 주세요.
          </div>

          <GridBox col={2} gap={20} style={{ width: "660px", marginBottom: "40px" }}>
            {DateTypeList.map((post, key) => (
              <div
                className={`pointer w-full h-60 flex-center rounded medium font-18 ${
                  key === dateType && "c-primary100"
                }`}
                key={`date-select-key-${key}`}
                style={{
                  border: key === dateType ? "2px solid #FA7521" : "2px solid #D2D6DB",
                }}
                onClick={() => {
                  setInputDate(null);
                  if (post === "날짜 직접 선택") {
                    setIsDateOpen(true);
                  }
                  setDateType(key);
                }}
              >
                {post === "날짜 직접 선택" && inputDate ? inputDate : post}
              </div>
            ))}
          </GridBox>

          <>
            <GridBox col={5} gap={20} style={{ width: "660px", marginBottom: "60px" }}>
              {TimeList.map((post, key) => (
                <div
                  className={`pointer w-full h-60 flex-center rounded medium font-18 ${
                    key === currentTime && "c-primary100"
                  }`}
                  key={`time-select-key-${key}`}
                  style={{
                    border: key === currentTime ? "2px solid #FA7521" : "2px solid #D2D6DB",
                  }}
                  onClick={() => {
                    if (dateType === 1) {
                      alert("통화가능 한 날짜를 먼저 선택해주세요!");
                      return;
                    }

                    setCurrentTime(key);
                  }}
                >
                  {post}
                </div>
              ))}
            </GridBox>
          </>

          <div
            className="w-full"
            style={{
              height: "1px",
              background: "#DCDCDC",
            }}
          />

          <div className="c-black bold font-28 mb-16 mt-60 let">
            희망하는 방문 일자를 선택해 주세요.
          </div>
          <div className="regular font-16 c-g mb-40">
            확정 방문일자가 희망 방문일자와 다를 수 있으며, 방문일자 확전 전 연락을 드립니다.
          </div>

          <GridBox col={2} gap={20} style={{ width: "660px", marginBottom: "40px" }}>
            {VisitDateList.map((post, key) => (
              <div
                className={`pointer w-full h-60 flex-center rounded medium font-18 ${
                  key === visitType && "c-primary100"
                }`}
                key={`date-select-key-${key}`}
                style={{
                  border: key === visitType ? "2px solid #FA7521" : "2px solid #D2D6DB",
                }}
                onClick={() => {
                  setVisitDate(null);
                  if (post === "날짜 직접 선택") {
                    setIsVisitOpen(true);
                  }
                  setVisitType(key);
                }}
              >
                {post === "날짜 직접 선택" && visitDate ? visitDate : post}
              </div>
            ))}
          </GridBox>

          <div className="flex-center mt-80">
            <div
              className="pointer c-g3 bg-g1 font-20 medium btn200 mr-20 rounded flex-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              이전
            </div>
            <div
              className="pointer c-white bg-primary100 font-20 medium btn200  rounded flex-center"
              onClick={() => {
                if (dateType === 0 && currentTime === null) return alert("시간대를 선택해주세요"); // 시간 상과없음 선택 시 alert 경고 수정
                // if (currentTime === null)
                //   return alert("시간대를 선택해주세요!");
                if (inputDate)
                  window.localStorage.setItem(
                    "callDate",
                    inputDate.replaceAll(" ", "").replaceAll(".", "")
                  );
                else window.localStorage.setItem("callDate", "상관 없음");

                if (visitDate)
                  window.localStorage.setItem(
                    "wantDate",
                    visitDate.replaceAll(" ", "").replaceAll(".", "")
                  );
                else window.localStorage.setItem("wantDate", "상관 없음");

                window.localStorage.setItem("callTime", TimeList[currentTime]);

                navigate(`/estimate/small/estimate?category=${category}&sub=${sub}`);
              }}
            >
              다음
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
