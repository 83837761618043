'use client'

import { useEffect } from 'react';
import styled from 'styled-components'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.4);
`

export const ModalBackground = ({
  isOpen,
  onClose,
  children,
  blockScroll=true
}) => {
  
  useEffect(() => {
    if(blockScroll && isOpen) document.body.style= `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [isOpen])

  return (
    <>
      {isOpen && 
        <Container className='flex-center z-9999999' onClick={onClose}>
          <div onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
            {children}
          </div>
        </Container>
      }
    </>
  )
}