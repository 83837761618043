import { GridBox } from "@/components";
import { Banner } from "@/ui/Banner/pc";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";

import styled from "styled-components";

const Container = styled.div`
  padding-top: 40px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 160px;
  .innerContainer {
    width: 100%;
    max-width: 90%;
    padding-bottom: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom: 1px solid #bdbdbd;
    text-align: center;
    p {
      color: #fd8a12;
    }
  }
  .border {
    height: 1px;
    width: 100%;
    background-color: #bdbdbd;
    margin-top: 80px;
    margin-bottom: 60px;
    max-width: 90%;
  }

  .listBtn {
    border: 1px solid #bdbdbd;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px 24px;
    font-size: 14px;
  }
`;

export default function Mobile() {
  const { id } = useParams();

  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const navigate = useNavigate();
  const [detail, setDetail] = useState(null);
  const modules = {
    toolbar: false,
  };
  async function getDetailData() {
    try {
      const { data } = await axios.get(`${backEndUrl}/story/search/${id}`);

      setDetail(data);
    } catch {
      alert("삭제된 스토리입니다!");
      return navigate("/story");
    }
  }

  useEffect(() => {
    getDetailData();
  }, []);
  const handleButtonClick = () => {
    const path = `/story`;
    const queryParams = { category: detail?.storyCategory };
    navigate(path, { state: queryParams });
  };
  return (
    <Container className="w-full flex flex-col align-center">
      <div className="innerContainer">
        <div className="title-container">
          <p className="font-20" bold>
            {detail?.storyCategory}
          </p>
          <h2 className=" medium c-black font-30 mt-15 mb-40 fontG">{detail?.storyTitle}</h2>
        </div>
        <div className="mt-40" w-full />
        <ReactQuill value={detail?.storyContent} readOnly={true} modules={modules} />
        <GridBox gap={1} col={1}></GridBox>
        <hr className="border" />
        <div className="listBtn" onClick={handleButtonClick}>
          목록으로
        </div>
      </div>
    </Container>
  );
}
