import axio from "axios";

const context = "https://surihomes.co.kr";

//const context = "http://localhost:3000";

const axiosInstance = axio.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST",
    "Access-Control-Allow-Headers": "Content-Type",
    "Content-Type": "application/json",
  },
  baseURL: context,
});

const axiosAuth = axio.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST",
    "Access-Control-Allow-Headers": "Content-Type",
    "Content-Type": "application/json",
  },
  baseURL: context,
});

axiosAuth.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error(error);
    Promise.reject(error);
  }
);

axiosAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error.response.status);
    if (error.response.status === 401) {
      const { email } = JSON.parse(localStorage.getItem("user_data"));
      const refesh = localStorage.getItem("user_refresh_token");
      const result = await axio.post(
        `${context}/auth/refresh`,
        { email },
        {
          headers: {
            RefreshToken: refesh,
          },
        }
      );

      if (result.status === 201) {
        console.log("refresh result", result);
        localStorage.setItem("user_access_token", result.data.accessToken);
        localStorage.setItem("user_refresh_token", result.data.refreshToken);

        error.config.headers.Authorization = `Bearer ${result.data.accessToken}`;
        return axio.request(error.config);
      } else {
        localStorage.removeItem("user_data");
        localStorage.removeItem("user_access_token");
        localStorage.removeItem("user_refresh_token");
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

export { context, axiosAuth };
