import AWS from "aws-sdk";

// AWS.config.update({
//   region: import.meta.env.VITE_APP_AWS_ACCESS_REGION,
//   accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
//   secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
// });
AWS.config.update({
  region: "ap-northeast-2",
  accessKeyId: "AKIAY343UDH37GL4747D",
  secretAccessKey: "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",
});

export default AWS;
