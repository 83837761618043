import { repair_data_item1, repair_data_item2, repair_data_item3, repair_data_item4,small_data_item4,
  small_data_item5,
  small_data_item10, } from "@/assets";

export const RepairList = [
  {
    img: repair_data_item1,
    title: '수도/설비'
  },
  {
    img: repair_data_item2,
    title: '전기/조명'
  },
  {
    img: repair_data_item3,
    title: '누수'

  },
  {
    img: repair_data_item4,
    title: '제품설치'
  },
  {
    img: small_data_item4,
    title: '창호'
  },
  {
    img: small_data_item5,
    title: '도어'
  },  {
    img: small_data_item10,
    title: '페인트'
  },
]
