import styled, { css } from "styled-components";
// import { ic_upload_file } from "assets"

import React, { useEffect, useRef, useState } from "react";

const FileUploadContainer = styled.div`
  position: relative;
  width: 70px;
  height: 70px;
  margin: 10px;
  display: flex;
  gap: 40px;
  justify-content: flex-start;

  .fileUploadUI {
    height: 200px;
  }
  .fileUploadBox {
    border-radius: 12px;
    border: 1px solid #e0e0e0;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #727272;
  }

  .fileUploadBox * {
    pointer-events: none;
  }
  .previewImage {
    width: 70px;
    height: 70px;
    border-radius: 12px;
    border: 1px solid #e0e0e0;
  }
`;
const PreviewImageWrapper = styled.div``;

const RemoveButton = styled.button`
  position: absolute;
  top: 7px;
  right: 5px;
  background-color: gray;
  color: white;
  border: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;
const Mobile = ({
  idx,
  label,
  className = "",
  text,
  accept,
  guide,
  efiles,
  setEFiles,
  maxSize,
  maxSizeStr,
  setEfilesName,
  isMobile = false,
  item,
  index,
}) => {
  const drop = useRef(null);
  const [dragging, setDragging] = useState(false);
  const maxArrayLength = [1, 2, 3, 4];
  const [fileNames, setFileNames] = useState([]);

  const [imagePreviewUrls, setImagePreviewUrls] = useState(Array(4).fill(null));
  useEffect(() => {
    drop?.current?.addEventListener("dragover", handleDragOver);
    drop?.current?.addEventListener("drop", handleDrop);
    drop?.current?.addEventListener("dragenter", handleDragEnter);
    drop?.current?.addEventListener("dragleave", handleDragLeave);

    return () => {
      if (drop?.current) {
        drop?.current?.removeEventListener("dragover", handleDragOver);
        drop?.current?.removeEventListener("drop", handleDrop);
        drop?.current?.removeEventListener("dragenter", handleDragEnter);
        drop?.current?.removeEventListener("dragleave", handleDragLeave);
      }
    };
  }, []);
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    // 파일 드롭 시 이벤트 호출
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
    const { files } = e.dataTransfer;

    if (files && files.length) {
      onUpload(files[0]);
    }
  };

  const handleInputChange = (e, index) => {
    const selectedFiles = e.target.files;

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      onUpload(file, index);
    }
  };

  const onUpload = (file, index) => {
    const newEFiles = [...efiles];
    newEFiles[index] = file;

    setEFiles(newEFiles);
    // const newEfilesName = [...efiles];
    // newEfilesName[index] = file.name;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const previewUrl = e.target.result;

      const newPreviewUrls = [...imagePreviewUrls];
      newPreviewUrls[index] = previewUrl;
      setImagePreviewUrls(newPreviewUrls);
    };
  };
  const removeImagePreview = (index) => {
    const newEFiles = [...efiles];
    newEFiles[index] = "";
    setEFiles(newEFiles);

    const newPreviewUrls = [...imagePreviewUrls];
    newPreviewUrls[index] = null;
    setImagePreviewUrls(newPreviewUrls);
  };

  return (
    <FileUploadContainer>
      <div className="fileInputCon" key={item}>
        <label className="fileUploadUI" htmlFor={`fileUpload${idx}_${item}`}>
          <div
            className="fileUploadBox pointer mt-8"
            ref={drop}
            style={dragging ? { background: "lightgrey" } : {}}
          >
            {imagePreviewUrls[index] ? (
              <PreviewImageWrapper>
                <img
                  src={imagePreviewUrls[index]}
                  alt={`Preview ${index}`}
                  className="previewImage"
                />
              </PreviewImageWrapper>
            ) : (
              <div className="font-10">파일 선택</div>
            )}
          </div>
        </label>

        <input
          id={`fileUpload${idx}_${item}`}
          type="file"
          accept={accept}
          style={{ display: "none" }}
          onChange={(e) => handleInputChange(e, index)}
        />

        {imagePreviewUrls[index] && (
          <RemoveButton
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              removeImagePreview(index);
            }}
          >
            X
          </RemoveButton>
        )}
      </div>
    </FileUploadContainer>
  );
};

export default Mobile;
