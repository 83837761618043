import { Banner } from '@/ui/Banner/mobile'
import Section1 from '@/ui/Main/section1/mobile'
import Section2 from '@/ui/Main/section2/mobile'
import Section3 from '@/ui/Main/section3/mobile'
import Section4 from '@/ui/Main/section4/mobile'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 56px;
`


export default function Mobile () {

  return (
    <Container>
      <Section1/>
      <Section2/>
      {/* <Section3/> */}
      <Section4/>

      <Banner/>
    </Container>
  )
}