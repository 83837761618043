import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "react-router-dom";

const Container = styled.div``;
function LayoutContainer({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return (
    <>
      <Container id="#wrap">
        <Header />

        <>{children}</>

        <Footer />
      </Container>
    </>
  );
}

export default LayoutContainer;
