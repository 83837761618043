import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GridBox } from "..";

const Container = styled.div`
  .bg-black {
    background-color: #141414;
  }

  .bg-white {
    background-color: #fff;
  }

  .rounded {
    border-radius: 4px;
  }
`;

export default function CategorySelector({
  options,
  current,
  setCurrent,
  isMobile = false,
  grid = 2,
  setSelectedCategory,
  categoryFromQuery,
}) {
  const navigate = useNavigate();

  const handleCategoryClick = (post, key) => {
    setCurrent(key);
    setSelectedCategory(post);

    if (categoryFromQuery) {
      // 카테고리를 선택하면 쿼리 파라미터를 지웁니다.
      navigate("/story");
    }
  };
  return (
    <Container className="flex align-center">
      {!isMobile ? (
        <div className="flex align-center w-full mr-8">
          {options.map((post, key) => (
            <div
              className={`pointer px-32 py-12 rounded bold ${
                current === key
                  ? "bg-black c-white" // 선택
                  : "bg-white c-g4" // 비선택
              }`}
              key={`option-item-key-${key}`}
              onClick={() => handleCategoryClick(post, key)}
            >
              {post}
            </div>
          ))}
        </div>
      ) : (
        <GridBox col={3} gap={6} className="w-full">
          {options.map((post, key) => (
            <div
              className={`w-full pointer font-12 py-12 rounded medium text-center ${
                current === key
                  ? "bg-black c-white" // 선택
                  : "bg-white c-g4" // 비선택
              }`}
              key={`option-item-key-${key}`}
              onClick={() => {
                setCurrent(key);

                setSelectedCategory(post);
              }}
            >
              {post}
            </div>
          ))}
        </GridBox>
      )}
    </Container>
  );
}
