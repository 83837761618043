import styled from "styled-components";
import { useMobileCheck } from "../../hooks";

import Mobile from "./components/mobile";
import PC from "./components/pc";

const Container = styled.div``;

export default function Footer() {
  const isMobile = useMobileCheck();

  return <Container>{isMobile ? <Mobile /> : <PC />}</Container>;
}
