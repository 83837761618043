import { header_logo, ic_arrow_right_grey } from "../../assets";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  background: rgba(0, 0, 0, 0.6);
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  width: 100vw;
  height: 100vh;
  z-index: 9999999999;

  position: fixed;
  top: 0;
  left: 0;

  .outBox {
    /* background: rgba(0, 0, 0, 0.6); */
    height: 100%;
  }

  .innerBox {
    animation: fadeInRight 0.5s;
    width: 80%;
    height: 100%;
    min-width: 200px;
    background: #ffffff;
    box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.08);

    padding-top: 80px;
    padding-bottom: 40px;
  }

  .profileBox {
    background: #ffffff;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
  }

  .call_btn {
    width: 157px;
    height: 36px;
    border-radius: 6px;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 64px;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  padding: 0 24px;

  .menuIcon {
    width: 20px;
    height: 20px;
  }
`;

export default function Menu({ isOpen, onClose }) {
  return (
    <>
      {isOpen && (
        <Container className="flex-center">
          <div className="outBox flex-1" onClick={onClose} />

          <div className="innerBox flex-col">
            <div className="profileBox px-6">
              <Link to={"/"}>
                <img src={header_logo} style={{ width: "120px", height: "40px" }} />
              </Link>
            </div>

            <StyledLink to="/repair" className="flex-center" onClick={onClose}>
              <div className="c-black">수리/보수</div>
              <div className="flex-1" />

              <img src={ic_arrow_right_grey} />
            </StyledLink>

            <StyledLink to="/small" className="flex-center" onClick={onClose}>
              <div className="c-black">스몰인테리어</div>
              <div className="flex-1" />
              <img src={ic_arrow_right_grey} />
            </StyledLink>

            <StyledLink to="/homecare" className="flex-center" onClick={onClose}>
              <div className="c-black">홈케어</div>
              <div className="flex-1" />
              <img src={ic_arrow_right_grey} />
            </StyledLink>

            <StyledLink to="/portfolio" className="flex-center" onClick={onClose}>
              <div className="c-black">포트폴리오</div>
              <div className="flex-1" />
              <img src={ic_arrow_right_grey} />
            </StyledLink>
            <StyledLink to="/story" className="flex-center" onClick={onClose}>
              <div className="c-black">수리스토리</div>
              <div className="flex-1" />
              <img src={ic_arrow_right_grey} />
            </StyledLink>
          </div>
        </Container>
      )}
    </>
  );
}
