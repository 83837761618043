import { ic_clock, ic_user } from "@/assets";
import { CategorySelector, GridBox, ImageModal, Pagination } from "@/components";
import { useBackEndUrl } from "@/hooks";
import { PortfoiloList } from "@/static/portfolio";
import { RepairList } from "@/static/repair";
import { Banner } from "@/ui/Banner/pc";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

const Container = styled.div`
  padding-top: 110px;
  .innerContainer {
    max-width: 1180px;
    width: 100vw;
    padding-bottom: 160px;
  }

  .gridImg {
    width: 100%;
    max-width: 100%;
  }

  .imgB {
    width: 100%;
    height: 187px;
    border-radius: 8px;
    object-fit: cover;
  }
`;

export default function PC() {
  const [searchParams, setSearchParams] = useSearchParams();
  const index = searchParams.get("index");
  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const awsUrl = process.env.REACT_APP_AWS_URL;
  const [current, setCurrent] = useState(index ? Number(index) : 0);
  const [category, setCategory] = useState(["수리/보수", "스몰 인테리어", "홈케어"]);

  const [selectedCategory, setSelectedCategory] = useState("수리/보수");
  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useState(0);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [beforeImg, setBeforeImge] = useState([]);
  const [afterImg, setAfterImge] = useState([]);
  const [selectedImgs, setSelectedImgs] = useState([]);
  const getImageUrl = async (list) => {
    const newBeforeImages = [];
    const newAfterImages = [];
    if (list.length > 0) {
      list?.map((item) => {
        const beforeImage = `${awsUrl}/gallery/${item.beforeImage}`;
        const afterImage = `${awsUrl}/gallery/${item.afterImage}`;
        newBeforeImages.push(beforeImage);
        newAfterImages.push(afterImage);
        setBeforeImge(newBeforeImages);
        setAfterImge(newAfterImages);
      });
    }
  };

  const getPortFolioList = async () => {
    const {
      data: { data, total },
    } = await axios({
      method: "GET",
      url: `${backEndUrl}/gallery`,
      params: {
        page,
        category: selectedCategory,
      },
    });

    if (Array.isArray(data)) {
      const filtering = data.filter((gallery) => gallery.isChecked);

      setTotal(total);
      setList(filtering);
      getImageUrl(data);
    } else {
      setList([]);
    }

    return data;
  };

  useEffect(() => {
    getPortFolioList();
  }, []);

  useEffect(() => {
    getPortFolioList();
  }, [selectedCategory]);

  return (
    <Container className="w-full flex flex-col align-center">
      <div className="px-20">
        <div className="innerContainer">
          <div className="medium c-black font-32 mb-60 fontG">포트폴리오</div>

          <ImageModal isOpen={isOpen} onClose={() => setIsOpen(false)} list={selectedImgs} />
          <CategorySelector
            options={category}
            current={current}
            setSelectedCategory={setSelectedCategory}
            setCurrent={(idx) => {
              setCurrent(idx);
              setPage(1);
            }}
          />
          <div className="mt-100" />
          <GridBox gap={20} col={3}>
            {list?.map((post, key) => (
              <div key={`gallery-key-${key}`} className=" mb-40">
                <div
                  className="flex-center w-full pointer"
                  onClick={() => {
                    setSelect(key);
                    setIsOpen(true);
                    setSelectedImgs([beforeImg[key], afterImg[key]]);
                  }}
                >
                  <div className="relative mr-6 flex-1">
                    <img className="mr-6 imgB" src={beforeImg[key]} alt={beforeImg[key]} />
                    <div
                      className="font-12 absolute px-6 py-4 c-white"
                      style={{
                        background: "rgba(0, 0, 0, 0.3)",
                        left: 0,
                        right: 0,
                        borderRadius: "6px",
                        bottom: "10px",
                        margin: "0 auto",
                        width: "fit-content",
                      }}
                    >
                      Before
                    </div>
                  </div>

                  <div className="relative flex-1">
                    <img className="mr-6 imgB" src={afterImg[key]} alt={afterImg[key]} />
                    <div
                      className="font-12 absolute px-6 py-4 c-white"
                      style={{
                        background: "rgba(0, 0, 0, 0.3)",
                        left: 0,
                        right: 0,
                        borderRadius: "6px",
                        bottom: "10px",
                        margin: "0 auto",
                        width: "fit-content",
                      }}
                    >
                      After
                    </div>
                  </div>
                </div>

                <div className="mt-20 font-20 bold c-black">{post.title}</div>
                <div className="mt-8 c-g800">{post.des}</div>

                <div className="mt-24 flex align-center">
                  <img src={ic_user} className="mr-6" alt={ic_user} />
                  <div className="font-14 c-g4 mr-18">{post.clientName} 고객님</div>

                  <img src={ic_clock} className="mr-6" alt={ic_clock} />
                  <div className="font-14 c-g4">{post.workDate}</div>
                </div>
              </div>
            ))}
          </GridBox>

          <Pagination marginTop={24} currentPage={page} totalPage={total} onClickPage={setPage} />
        </div>
      </div>
      <Banner />
    </Container>
  );
}
