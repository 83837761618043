import { pick, isNil } from "lodash";

export const validateEmail = (value) => {
  const emailRule =
    /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  if (!value) return false;
  if (value.length === 0 || emailRule.test(value)) return true;
  return false;
};

export const phoneValidate = (text) => {
  if (text === "" || !text) return false;
  var rule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
  return rule.test(text);
};

export const telValidate = (text) => {
  if (text === "" || !text) return false;
  var rule = /^(070|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/;
  return rule.test(text);
};

export const verificationCodeValidate = (value) => {
  const verificationCodeRule = /^[0-9]{6}$/;
  if (typeof value !== "string") {
    return false;
  }
  return verificationCodeRule.test(value);
};
// export const phoneAuthen
