import { CategorySelector, GridBox } from '@/components'
import { HomecareList } from '@/static/homecare'
import { RepairList } from '@/static/repair'
import { SmallList } from '@/static/small'
import { Banner } from '@/ui/Banner/mobile'
import { useState } from 'react'
import { useNavigate  } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 40px;
  .innerContainer {
    width: 100%;
    padding-bottom: 80px;
  }

  .gridImg {
    width: 100%;
    max-width: 100%;
  }
`

const LinkList = ['/repair', '/small', '/homecare']


export default function PC () {
  const [current, setCurrent] = useState(0)
  const [category, setCategory] = useState(['수리/보수', '스몰 인테리어', '홈케어'])

  const navigate = useNavigate ()

  function handleClick(name) {
    // 초기화 ( 임시저장 활성 시 변경 )
    window.localStorage.clear()
    if(current === 1)
      navigate(`/estimate/small?category=${category[current].replaceAll('/', '^')}&sub=${name.replaceAll('/', '^')}`)
    else
    navigate(`/estimate/homecare?category=${category[current].replaceAll('/', '^')}&sub=${name.replaceAll('/', '^')}`)
  }
  
  function get_array(idx) {
    switch(idx) {
      case 0:
        return RepairList
      case 1:
        return SmallList
      case 2:
        return HomecareList
    }
  }

  return (
    <Container className='w-full flex flex-col align-center'>
      <div className='innerContainer px-20'>
        <div className="w-full medium c-black font-20 mb-24 fontG text-center">견적 서비스 신청</div>
        
        <CategorySelector
          options={category} 
          current={current}
          setCurrent={(idx) => {
            setCurrent(idx)
          }}
          isMobile={true}
        />

        <div className='mt-40'/>

        <GridBox gap={20} col={1}>
          {
            get_array(current).map((post, key) => (
              <div className='w-full pointer' key={`repair-post-${key}`} onClick={() => handleClick(post.title)}>
                <img className='gridImg w-full' src={post.img}/>
                <div className='fontP medium font-14 mt-10 c-g900'>{post.title}</div>
              </div>
            ))
          }
        </GridBox>
      </div>

      <Banner/>
    </Container>
  )
}