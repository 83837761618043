import styled from "styled-components";
import DatePicker from "react-datepicker";

import { ModalBackground } from "../Modal/ModalBackground";

import { ko } from "date-fns/esm/locale";

import "react-datepicker/dist/react-datepicker.css";

const Container = styled.div`
  .react-datepicker {
    width: calc(100vw - 4px) !important;
    height: 380px;

    padding: 30px 16px;
    border-radius: 13px;
  }

  .react-datepicker__month-container {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__header {
    background-color: #fff !important;
    width: 100% !important;
    border: 0;
    padding: 0;
  }

  .react-datepicker__navigation--previous {
    top: 36px;
    left: 16px;
    background-image: url("/assets/icon/dateLeft.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 10px;
    height: 17.32px;
  }

  .react-datepicker__navigation--next {
    top: 36px;
    right: 16px;
    background-image: url("/assets/icon/dateRight.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 10px;
    height: 17.32px;
  }

  .react-datepicker__navigation-icon--next,
  .react-datepicker__navigation-icon--previous {
    top: 2px !important;
  }

  .react-datepicker__current-month {
    font-size: 20px;
    margin-bottom: 13px;
  }

  .react-datepicker__day {
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin: auto;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .react-datepicker__day-name {
    width: 100%;
    margin: auto;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: grid;
    grid-template-columns: 7;
    grid-auto-flow: column;
  }

  .react-datepicker__day-names {
    margin-bottom: 13px;
  }

  .react-datepicker__day--today {
    font-weight: 400;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
    color: inherit;
  }
`;

const Mobile = ({ isOpen, setIsOpen, startDate, setStartDate }) => {
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };
  const isDateSelectable = (date) => {
    return (
      date >= startDate || date.toDateString() === startDate.toDateString()
    );
  };
  return (
    <ModalBackground isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Container>
        <DatePicker
          onChange={handleChange}
          inline
          filterDate={isDateSelectable}
          startDate={startDate}
          locale={ko}
          formatWeekDay={(name) => {
            return name;
          }}
        />
      </Container>
    </ModalBackground>
  );
};

export default Mobile;
