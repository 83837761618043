export const HomecarePortfolio = [
  {
    title: "줄눈시공",
    before: "https://surihomes-file.s3.ap-northeast-2.amazonaws.com/portfolio/sample_before.png",
    after: "https://surihomes-file.s3.ap-northeast-2.amazonaws.com/portfolio/sample_after.png",
    name: "OOO",
    date: "22.01.01",
    des: "대리석연마, 광택코팅, 크랙보수"
  },
  {
    title: "줄눈시공",
    before: "https://surihomes-file.s3.ap-northeast-2.amazonaws.com/portfolio/sample_before.png",
    after: "https://surihomes-file.s3.ap-northeast-2.amazonaws.com/portfolio/sample_after.png",
    name: "OOO",
    date: "22.01.01",
    des: "대리석연마, 광택코팅, 크랙보수"
  },
  {
    title: "줄눈시공",
    before: "https://surihomes-file.s3.ap-northeast-2.amazonaws.com/portfolio/sample_before.png",
    after: "https://surihomes-file.s3.ap-northeast-2.amazonaws.com/portfolio/sample_after.png",
    name: "OOO",
    date: "22.01.01",
    des: "대리석연마, 광택코팅, 크랙보수"
  },
  {
    title: "줄눈시공",
    before: "https://surihomes-file.s3.ap-northeast-2.amazonaws.com/portfolio/sample_before.png",
    after: "https://surihomes-file.s3.ap-northeast-2.amazonaws.com/portfolio/sample_after.png",
    name: "OOO",
    date: "22.01.01",
    des: "대리석연마, 광택코팅, 크랙보수"
  },
]
