import { main_section2_list1_1, main_section2_list1_2, main_section2_list1_3, main_section2_list1_4, main_section2_list2_1, main_section2_list2_10, main_section2_list2_11, main_section2_list2_12, main_section2_list2_2, main_section2_list2_3, main_section2_list2_4, main_section2_list2_5, main_section2_list2_6, main_section2_list2_7, main_section2_list2_8, main_section2_list2_9, main_section2_list3_1, main_section2_list3_2, main_section2_list3_3, main_section2_list3_4 } from '@/assets';
import { GridBox } from '@/components';
import { HomecareList } from '@/static/homecare';
import { RepairList } from '@/static/repair';
import { SmallList } from '@/static/small';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 80px 20px;
  min-height: 780px;

  .innerSection {
    width: 100%;
  }
`


export default function Section2 () {
  const [current, setCurrent] = useState(1);
  
  const navigate = useNavigate()
  
  function handleClick(idx) {
    // 초기화 ( 임시저장 활성 시 변경 )
    window.localStorage.clear()
    if(current === 1) {
      navigate(`/estimate/homecare?category=수리^보수&sub=${RepairList[idx].title.replaceAll('/', '^')}`) 
      return;
    }
    if(current === 2) {
      navigate(`/estimate/small?category=스몰 인테리어&sub=${SmallList[idx].title.replaceAll('/', '^')}`) 
      return;
    }
    if(current === 3) {
      navigate(`/estimate/homecare?category=홈케어&sub=${HomecareList[idx].title.replaceAll('/', '^')}`) 
      return;
    }
    throw new Error('잘못된 접근 방식입니다.');
    // 오공 Code
      // navigate(`/estimate/homecare?category=${['수리/보수', '스몰 인테리어', '홈케어'][current].replaceAll('/', '^')}&sub=${getList(current)[idx].title.replaceAll('/', '^')}`)
  }
  
  return (
    <Container className='flex-col align-center flex' id="section2_anker">
      <div className='innerSection'>
        <div className='c-primary font-14 meidum fontE'>Service</div>
        <div className='mt-14 font-20 medium fontE c-g900 line-28 mb-40'>
          고객의 수리, 인테리어를 책임지고자<br/>
          다양한 서비스를 제공합니다.
        </div>

        <div className='flex mb-32'>
          {
            ['수리/보수', '스몰인테리어', '홈케어'].map((post, key) => (
              <CategoryButton
                text={post}
                isCurrent={key + 1 === current}
                onClick={() => setCurrent(key + 1)}
                key={`category-selector-${key + 1}`}
              />
            ))
          }
        </div>

        <GridBox col={3} gap={10}>
          {
            CategoryList[current -1].map((post, key) => 
              (
              <img className='w-full' src={post} key={`category-img-${current}-${key}`}  onClick={() => handleClick(key)}/>
            ))
          }
        </GridBox>
      </div>
    </Container>
  )
}


const CategotyButtonContainer = styled.div`
  color: ${({isCurrent}) => isCurrent ? '#141414' : '#BDBDBD'};
  border-bottom: ${({isCurrent}) => isCurrent ? '4px solid #141414' : 'none'};
`

const CategoryButton = ({
  isCurrent,
  text,
  onClick
}) => {
  return (
    <CategotyButtonContainer className='pointer category_btn mr-20 font-18 bold fontP' isCurrent={isCurrent} onClick={onClick}>
      {text}
    </CategotyButtonContainer>
  )
}


const CategoryList = [
  [main_section2_list1_1, main_section2_list1_2, main_section2_list1_3, main_section2_list1_4],
  [main_section2_list2_1, main_section2_list2_2, main_section2_list2_3, main_section2_list2_4, main_section2_list2_5, main_section2_list2_6, main_section2_list2_7, main_section2_list2_8, main_section2_list2_9, main_section2_list2_10, main_section2_list2_11, main_section2_list2_12],
  [main_section2_list3_1, main_section2_list3_2, main_section2_list3_3, main_section2_list3_4]
]

