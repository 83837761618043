import DaumPostCode from 'react-daum-postcode';

import styled from 'styled-components'
import { ModalBackground } from './ModalBackground';


const Container = styled.div`
  .post-code {
    width: ${({isMobile}) => isMobile ? '90vw' : '600px'} !important;
    height: ${({isMobile}) => isMobile ? '400px' : '450px'} !important;
  }
`


export const DaumPostModal = ({
  isOpen,
  onClose,
  onSubmit,
  isMobile=false
}) => {
  const handleComplete = (data) => {
    const { 
      zonecode, // 우편번호 
      address,
      jibunAddress // 지번
    } = data

    onSubmit({ zonecode, address })
    onClose()
  }
  return (
    <ModalBackground
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container isMobile={isMobile}>
        <DaumPostCode 
          onComplete={handleComplete} 
          className="post-code" 
        />
        
      </Container>
    </ModalBackground>
  )
}