import { useState, useEffect } from "react";
import axios from "axios";
import { phoneValidate, verificationCodeValidate } from "@/utils/validate";
import { ModalBackground } from "@/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getTheme } from "@/styles/theme";
import { Input } from "@/components/Input";
import { AddressInput } from "@/components/AddressInput";
import { RadioButton } from "@/components/Radio";
import { CheckBox } from "@/components/Checkbox";
import { ic_close, main_section2_list1_1 } from "@/assets";
import { PhoneInput } from "@/components/PhoneInput";
import { PhoneIdentificationInput } from "@/components/PhoneIdentificationInput";

const Container = styled.div`
  .innerSection {
    width: 480px;
  }

  .bg-primary100 {
    background-color: ${getTheme("primary100")};
  }

  .bg-g3 {
    background-color: ${getTheme("g3")};
  }

  .bg-g1 {
    background-color: ${getTheme("g1")};
  }

  .bg-white {
    background-color: ${getTheme("white")};
  }

  .w-6 {
    width: 24px;
  }

  .w-3px {
    width: 3px;
  }

  .h-6 {
    height: 24px;
  }

  .h-3px {
    height: 3px;
  }

  .rounded-48px {
    border-radius: 48px;
  }

  .rounded-16px {
    border-radius: 16px;
  }

  .let {
    letter-spacing: -2.8px;
  }

  .rounded {
    border-radius: 4px;
  }

  .button {
    width: 200px;
    height: 64px;
  }

  .h-60 {
    height: 60px;
  }

  .btn200 {
    width: 200px;
    height: 64px;
  }
  .estimate-summary-container {
    width: 80%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: scroll;
  }
  .border {
    height: 1px;
    width: 100%;
    background-color: #e0e0e0;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

export default function PC() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const backEndUrl = process.env.REACT_APP_BACK_END_URL;
  const category = searchParams.get("category");
  const sub = searchParams.get("sub");

  const [callTime, setCallTime] = useState(window.localStorage.getItem("callTime"));
  const [callDate, setCallDate] = useState(window.localStorage.getItem("callDate"));
  const [content, setContent] = useState(window.localStorage.getItem("content"));
  const [wantDate, setWantDate] = useState(window.localStorage.getItem("wantDate"));
  const [supplyArea, setSupplyArea] = useState(window.localStorage.getItem("supplyArea"));
  const [roomCount, setRoomCount] = useState(window.localStorage.getItem("roomCount"));
  const [wantPrice, setWantPrice] = useState(window.localStorage.getItem("wantPrice"));
  const [service, setService] = useState(JSON.parse(window.localStorage.getItem("service")));
  const [name, setName] = useState(window.localStorage.getItem("name") ?? "");
  const [phone, setPhone] = useState(window.localStorage.getItem("phone") ?? "");
  const [inputVerificationCode, setInputVerificationCode] = useState(
    window.localStorage.getItem("inputVerificationCode") ?? ""
  );
  const [zondecode, setZonecode] = useState(window.localStorage.getItem("zoneCode") ?? "");
  const [address, setAddress] = useState(window.localStorage.getItem("address") ?? "");
  const [addressDetail, setAddressDetail] = useState(
    window.localStorage.getItem("addressDetail") ?? ""
  );

  const [parking, setParking] = useState(
    window.localStorage.getItem("parking")
      ? window.localStorage.getItem("parking") === "true"
        ? true
        : false
      : null
  );
  const [privacy, setPrivacy] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isSendIdentification, setIsSendIdentification] = useState(false);
  const [checkIdentification, setCheckIdentification] = useState(false);
  const [isSendVerificationCodeCoolDown, setIsSendVerificationCodeCooldown] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const sendVerificationCode = async () => {
    let phone = window.localStorage.getItem("phone");
    if (!phone) {
      alert("연락처를 입력해주세요");
      return;
    }
    if (isSendVerificationCodeCoolDown) {
      alert("인증번호 재 발송은 1분이후에 가능합니다.");
      return;
    }
    try {
      if (!phone) {
        alert("휴대폰 번호를 입력해주세요.");
        return;
      }
      setIsSendVerificationCodeCooldown(true);
      setRemainingTime(180);
      await axios({
        method: "POST",
        url: `${backEndUrl}/aligo`,

        headers: { "Content-Type": "application/json" },
        data: {
          phone,
        },
      });
      setIsSendIdentification(true);
      setTimeout(() => {
        setIsSendVerificationCodeCooldown(false);
        setRemainingTime(0);
      }, 180000);
    } catch (e) {
      console.error(e);
      setIsSendVerificationCodeCooldown(false);
      setRemainingTime(0);
    }
  };
  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [remainingTime]);

  const checkVerificationCode = async () => {
    let verificationCode = window.localStorage.getItem("inputVerficationCode");
    let phone = window.localStorage.getItem("phone");
    try {
      if (!phone) alert("휴대폰 번호를 입력해주세요.");
      const response = await axios({
        method: "POST",
        url: `${backEndUrl}/verify`,

        headers: { "Content-Type": "application/json" },
        data: { phone, verificationCode },
      });
      if (response.data === true) {
        setCheckIdentification(true);
        setIsSendIdentification(false);
        setRemainingTime(0);
      }
      if (response.data === false) alert("인증번호를 확인해주세요!");
    } catch (e) {
      console.error(e);
    }
  };

  async function submit() {
    let callTime = window.localStorage.getItem("callTime");
    let callDate = window.localStorage.getItem("callDate");
    let content = window.localStorage.getItem("content");
    let wantDate = window.localStorage.getItem("wantDate");
    let uploadedImageKeys = JSON.parse(window.localStorage.getItem("uploadedImageKeys"));
    try {
      await axios({
        method: "POST",
        url: `${backEndUrl}/estimate-summary`,

        headers: { "Content-Type": "application/json" },
        data: {
          stat: 0,
          name,
          phone,
          address,
          addressDetail,
          supplyArea,
          roomCount,
          service,
          content,
          callDate,
          callTime,
          wantDate,
          wantPrice,
        },
      });
      await axios.post(`${backEndUrl}/estimate/home`, {
        type: sub,
        content,
        callTime,
        callDate,
        wantDate,
        clientName: name,
        clientPhone: phone,
        clientAddress: address,
        clientDetailAddress: addressDetail,
        parking,
        privacy,
        agreement,
        uploadedImageKeys,
      });

      window.localStorage.clear();
      setTimeout(() => (window.location.href = "/estimate/finish"), 500);
    } catch (e) {
      console.log(e);
      alert("저장에 실패했습니다!");
    }
  }

  async function openModal() {
    if (!name) return alert("이름을 입력해주세요!");
    if (!phone) return alert("연락처를 입력해주세요!");
    if (!checkIdentification) return alert("휴대폰 인증 해주세요!");
    if (!address) return alert("주소를 입력해주세요!");
    if (!zondecode) return alert("우편번호를 입력해주세요!");
    if (parking === null) return alert("주차 정보를 선택해주세요!");
    if (!privacy) return alert("개인정보 수집에 동의해주세요!");
    if (!agreement) return alert("이용 동의를 선택해주세요!");

    if (!phoneValidate(phone)) return alert("연락처가 형식에 맞지 않습니다!");

    setIsOpen(true);
  }
  const callYear = callDate.substring(0, 4);
  const callMonth = callDate.substring(4, 6);
  const claaDay = callDate.substring(6, 8);
  const formattedCallDate = `${callYear}년 ${callMonth}월 ${claaDay}일`;

  const wantYear = wantDate.substring(0, 4);
  const wantMonth = wantDate.substring(4, 6);
  const wantDay = wantDate.substring(6, 8);
  const formattedWantDate = ` ${wantYear}년 ${wantMonth}월 ${wantDay}일`;
  return (
    <Container>
      <div className="w-full flex-center flex-col pb-40">
        <ModalBackground isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <div
            className="bg-white px-10 py-30"
            style={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              width: "465px",
              alignItems: "center",
            }}
          >
            <div className="flex-center w-full">
              <div className="flex-1" />
              <img className="pointer" src={ic_close} onClick={() => setIsOpen(false)} />
            </div>

            <div className="text-center bold font-20 mt-8">수리홈즈 서비스를 신청하시겠습니까?</div>
            <div className="mt-8 text-center font-14">
              신청 후 서비스 수정이 불가능하니 다시 한 번 확인해 주세요.
            </div>
            <div className="estimate-summary-container mt-30 px-30 py-20">
              <h3>신청 내역</h3>
              <p className="mt-2">
                성함 : <span>{name}</span>
              </p>
              <hr className="border" />
              <p className="mt-2">
                연락처 : <span>{phone}</span>
              </p>
              <hr className="border" />
              <p className="mt-2">
                주소 :
                <span>
                  {address} {addressDetail}
                </span>
              </p>
              <hr className="border" />

              <p className="mt-2">
                요청사항 : <span>{content}</span>
              </p>
              <hr className="border" />
              <p className="mt-2">
                희망 상담 일자 :{" "}
                <span>
                  {callDate === "상관 없음" ? callDate : formattedCallDate}{" "}
                  {callTime !== "undefined" ? callTime : ""}
                </span>
              </p>
              <hr className="border" />
              <p className="mt-2">
                희망 방문 일자 :{" "}
                <span> {wantDate === "상관 없음" ? wantDate : formattedWantDate} </span>
              </p>
              <hr className="border" />
              <p className="mt-2">
                희망 예상 견적 : <span>{wantPrice}</span>
              </p>
              <hr className="border" />
            </div>

            <div className="flex-center mt-24">
              <div
                className="pointer c-g3 mr-24 rounded medium flex-center"
                style={{
                  border: "1px solid #D2D6DB",
                  width: "180px",
                  height: "44px",
                }}
                onClick={() => setIsOpen(false)}
              >
                내용 수정
              </div>
              <div
                className="pointer c-white rounded medium bg-primary100 flex-center"
                onClick={() => {
                  submit();
                }}
                style={{ width: "180px", height: "44px" }}
              >
                수리 신청
              </div>
            </div>
          </div>
        </ModalBackground>

        <div className="innerSection flex-center pt-120 flex-col">
          <div className="flex-center mb-16">
            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px c-white font-14 fontG">
              1
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px ml-6 c-white font-14 fontG">
              2
            </div>
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />
            <div className="rounded-16px w-3px h-3px bg-g3 ml-6" />

            <div className="bg-primary100 w-6 h-6 flex-center rounded-48px ml-6 c-white font-14 fontG">
              3
            </div>
          </div>

          <div className="font-18 mb-60">
            홈케어 {">"} <span>{category.replaceAll("^", "/")}</span> {">"}{" "}
            <span className="c-primary100 medium">{sub.replaceAll("^", "/")}</span>
          </div>

          <div className="c-black bold font-28 mb-40 let">
            방문을 위해 필요한 정보를 입력해 주세요.
          </div>

          <Input
            label="성함"
            placeholder="성함"
            value={name}
            onChange={(text) => {
              setName(text);
              window.localStorage.setItem("name", text);
            }}
          />

          <PhoneInput
            label="연락처"
            placeholder="연락처"
            value={phone}
            wrongText={"*잘못된 형식입니다."}
            wrong={phone !== "" && !phoneValidate(phone)}
            validate={/^[0-9]+$/}
            onChange={(text) => {
              setPhone(text);
              window.localStorage.setItem("phone", text);
            }}
            setIsSendIdentification={setIsSendIdentification}
            sendVerificationCode={sendVerificationCode}
            checkIdentification={checkIdentification}
            setCheckIdentification={setCheckIdentification}
            remainingTime={remainingTime}
          />
          {isSendIdentification && (
            <PhoneIdentificationInput
              label="휴대폰 인증 번호"
              placeholder="숫자 6자리 입력"
              autocomplete="one-time-code"
              value={inputVerificationCode}
              wrongText={"*잘못된 형식입니다!"}
              wrong={
                inputVerificationCode !== "" && !verificationCodeValidate(inputVerificationCode)
              }
              validate={/^[0-9]+$/}
              onChange={(text) => {
                setInputVerificationCode(text);
                window.localStorage.setItem("inputVerficationCode", text);
              }}
              checkVerificationCode={checkVerificationCode}
              setInputVerificationCode={setInputVerificationCode}
              checkIdentification={checkIdentification}
            />
          )}
          <AddressInput
            zonecode={zondecode}
            setZonecode={(text) => {
              setZonecode(text);
              window.localStorage.setItem("zoneCode", text);
            }}
            address={address}
            setAddress={(text) => {
              setAddress(text);
              window.localStorage.setItem("address", text);
            }}
            addressDetail={addressDetail}
            setAddressDetail={(text) => {
              setAddressDetail(text);
              window.localStorage.setItem("addressDetail", text);
            }}
          />

          <div className="flex-center w-full mb-16">
            <div className="">주차 정보</div>
            <div className="flex-1" />
            <div className="c-g3 font-14">*유료 주차인 경우 주차비 금액이 추가됩니다.</div>
          </div>

          <div className="w-full">
            <RadioButton
              label={"무료 주차"}
              selected={parking === true}
              onClick={() => {
                setParking(true);
                window.localStorage.setItem("parking", true);
              }}
            />

            <div className="mt-8" />

            <RadioButton
              label={"유료 주차"}
              selected={parking === false}
              onClick={() => {
                setParking(false);
                window.localStorage.setItem("parking", false);
              }}
            />
          </div>

          <div className="mb-8 mt-40 w-full">이용약관</div>

          <div className="w-full">
            <div className="flex-center mb-8">
              <CheckBox
                label={"개인정보 수집"}
                selected={privacy === true}
                onClick={() => setPrivacy(!privacy)}
              />

              <div className="flex-1" />

              <a
                href="/policy/privacy"
                target={"_blank"}
                className="underline c-primary100 pointer"
              >
                자세히보기
              </a>
            </div>

            <div className="flex-center mb-8">
              <CheckBox
                label={"이용 동의"}
                selected={agreement === true}
                onClick={() => setAgreement(!agreement)}
              />

              <div className="flex-1" />

              <a href="/policy/service" className="underline c-primary100 pointer">
                자세히보기
              </a>
            </div>
          </div>

          <div className="flex-center mt-80">
            <div
              className="pointer c-g3 bg-g1 font-20 medium btn200 mr-20 rounded flex-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              이전
            </div>
            <div
              className="pointer c-white bg-primary100 font-20 medium btn200  rounded flex-center"
              onClick={() => {
                openModal();
              }}
            >
              신청
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
